import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import InputLabel from 'components/layout/forms/InputLabel';
import IPermission from 'interfaces/users/IPermission';
import LinkButton from 'components/layout/buttons/LinkButton';
import translations from 'services/translations/translations';
import { fetchPermissionTypes } from 'store/users/actions';

import Permission from './Permission';

const Permissions = ({
  permissions,
  setPermissions,
}: {
  permissions: IPermission[];
  setPermissions: (permissions: IPermission[]) => void;
}): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation([
    translations.admin,
    translations.userManagement,
  ]);

  useEffect(() => {
    dispatch(fetchPermissionTypes());
  }, [dispatch]);

  const addPermission = () => {
    setPermissions([...permissions, { target: '', type: '' }]);
  };

  const getPermissionRemover = (indexToRemove: number) => () => {
    setPermissions(
      permissions.filter((permission, index) => index !== indexToRemove)
    );
  };

  const getPermissionSetter =
    (indexToUpdate: number) => (updatedPermission: IPermission) => {
      setPermissions(
        permissions.map((permission, index) =>
          index === indexToUpdate ? updatedPermission : permission
        )
      );
    };

  return (
    <>
      <h3>{t(`${translations.admin}:Permissions`)}</h3>
      <Row>
        <Col xs="6" md="6" lg="6" xl="4">
          <InputLabel>{t(`${translations.admin}:Permission`)}</InputLabel>
        </Col>
        <Col xs="5" md="5" lg="5" xl="4">
          <InputLabel>{t(`${translations.userManagement}:Target`)}</InputLabel>
        </Col>
      </Row>
      {permissions.map((permission, index) => (
        <Permission
          key={`permission-${index}`}
          permission={permission}
          removePermission={getPermissionRemover(index)}
          setPermission={getPermissionSetter(index)}
        />
      ))}
      <div className="add-permission-row">
        <LinkButton onClick={addPermission}>
          {`+ ${t(`${translations.common}:Add row`)}`}
        </LinkButton>
      </div>
    </>
  );
};

export default Permissions;
