import IProductConfigurationItem from 'interfaces/products/IProductConfigurationItem';

import {
  CATEGORY,
  CUSTOM_INSTALLATION,
  INSTALLATION_ID,
  INSTALLATION_PRICE,
  INSTALLATION_PRICE_UNIT,
  IS_CUSTOM_INSTALLATION,
  QUANTITY,
  QUANTITY_UNIT,
  SELLING_PRICE,
  SELLING_PRICE_UNIT,
  SOCIAL_SECURITY_COST_PERCENTAGE,
} from 'services/products/productConfigurationAttributeTypes';

import getDefaultInstallation from '../installations/getDefaultInstallation';
import IInstallation from '../../IInstallation';

const attributeValueGetter =
  (item: IProductConfigurationItem) =>
  (type: string): string => {
    const attribute = item.attributes.find((a) => a.type === type);

    if (attribute === undefined) {
      return '';
    }

    if (attribute.value === null) {
      return '';
    }

    return attribute.value;
  };

const getInstallationId = (item: IProductConfigurationItem) => {
  const productId = item.attributes.find(
    (attribute) => attribute.type === INSTALLATION_ID
  );

  if (productId === undefined || productId.value === null) {
    return null;
  }

  if (productId.displayValue === undefined) {
    return null;
  }

  if (productId.displayValue === null) {
    return null;
  }

  return {
    label: productId.displayValue,
    value: productId.value,
  };
};

export default (
  installationItems: IProductConfigurationItem[],
  nextKey: number
): IInstallation[] =>
  installationItems.map((installationItem, index) => {
    const installation = getDefaultInstallation(nextKey + index, '');
    const getAttributeValue = attributeValueGetter(installationItem);

    return {
      ...installation,
      category: getAttributeValue(CATEGORY),
      customInstallation: getAttributeValue(CUSTOM_INSTALLATION),
      installation: getInstallationId(installationItem),
      installationPrice: getAttributeValue(INSTALLATION_PRICE),
      installationPriceUnit: getAttributeValue(INSTALLATION_PRICE_UNIT),
      isCustomInstallation: getAttributeValue(IS_CUSTOM_INSTALLATION) !== '0',
      quantity: getAttributeValue(QUANTITY),
      quantityUnit: getAttributeValue(QUANTITY_UNIT),
      sellingPrice: getAttributeValue(SELLING_PRICE),
      sellingPriceUnit: getAttributeValue(SELLING_PRICE_UNIT),
      socialSecurityCostPercentage: getAttributeValue(
        SOCIAL_SECURITY_COST_PERCENTAGE
      ),
    };
  });
