import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import IBasicInfoGet from 'api/interfaces/projects/IBasicInfoGet';
import IPlaceGet from 'api/interfaces/projects/IPlaceGet';
import apiProjects from 'api/projects';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import EditIcon from 'components/layout/icons/EditIcon';
import BasicTableExpandable from 'components/layout/tables/BasicTableExpandable';
import Subtable from 'components/layout/tables/Subtable';
import translations from 'services/translations/translations';
import IBuildingStructure from 'interfaces/projects/IBuildingStructure';
import IBuilding from 'interfaces/projects/IBuilding';
import { max } from 'date-fns';
import getMillimiterUnit from './getMillimiterUnit';
import getSquares from './getSquares';
import {
  PROJECT_MEASUREMENT_BASIC_INFO,
  PROJECT_MEASUREMENT_ADD_MEASUREMENT,
} from '../sectionTypes';
import getPlaceColumns from './getPlaceColumns';
import getPlaceSubColumns from './getPlaceSubcolumns';
import getPlaceInfo from './getPlaceInfo';
import getAllSquares from './getAllSquares';
import Status from './Status';
import SearchBars from './SearchBars';

import 'style/projects/projectMeasurementTab/addMeasurement/ProjectMeasurementList.css';

const ProjectMeasurementList = ({
  setSection,
  setPlaceId,
}: {
  setSection: (sectionName: string) => void;
  setPlaceId: (placeId: number) => void;
}): JSX.Element => {
  const { t } = useTranslation([translations.projects, translations.common]);
  const { projectId } = useParams();
  const [measurementsRaw, setMeasurementsRaw] = useState<IPlaceGet[]>();
  const [filteredMeasurements, setFilteredMeasurements] =
    useState<IPlaceGet[]>();
  const [basicInfo, setBasicInfo] = useState<IBasicInfoGet>();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const idsRef = useRef(selectedIds);
  const [modalSaved, setModalSaved] = useState(false);
  const [maxFloors, setMaxFloors] = useState<number>(0);

  const colTitles = {
    id: t(`${translations.projects}:Building Space`),
    status: t(`${translations.projects}:status.Status`),
    position: t(`${translations.projects}:Position`),
    height: t(`${translations.projects}:Height`),
    width: t(`${translations.projects}:Width`),
    squares: t(`${translations.projects}:Squares`),
  };

  useEffect(() => {
    if (measurementsRaw !== undefined) {
      if (measurementsRaw.length === 0) {
        setMaxFloors(0);
        return;
      }

      const maxFloorMeasurement = measurementsRaw.reduce(
        (prev: IPlaceGet | null, current: IPlaceGet) => {
          if (prev === null) {
            return current;
          }

          return prev.floor > current.floor ? prev : current;
        },
        null
      );

      setMaxFloors(
        maxFloorMeasurement === null ? 0 : maxFloorMeasurement.floor
      );
    }
  }, [measurementsRaw]);

  const filterMeasurements = (filters: {
    building: string;
    space: string;
    section: string;
    floor: string;
    status: string;
  }) => {
    let newMeasurements: IPlaceGet[] | undefined = measurementsRaw;

    if (filters.space !== '') {
      const spaceId = basicInfo?.buildingSpaces.find(
        (space: IBuildingStructure) => space.name === filters.space
      )?.id;
      newMeasurements = newMeasurements?.filter(
        (measur: IPlaceGet) => measur.buildingSpaceId === spaceId
      );
    }

    if (filters.status !== '') {
      newMeasurements = newMeasurements?.filter(
        (measur: IPlaceGet) => measur.status === filters.status
      );
    }

    if (filters.building !== '') {
      const buildId = basicInfo?.buildings.find(
        (build: IBuilding) => build.name === filters.building
      )?.id;
      newMeasurements = newMeasurements?.filter(
        (measur: IPlaceGet) => measur.buildingId === buildId
      );
    }

    if (filters.floor !== '') {
      newMeasurements = newMeasurements?.filter(
        (measur: IPlaceGet) => measur.floor.toString() === filters.floor
      );
    }

    if (filters.section !== '') {
      const secId = basicInfo?.buildingSections.find(
        (sec: IBuildingStructure) => sec.name === filters.section
      )?.id;
      newMeasurements = newMeasurements?.filter(
        (measur: IPlaceGet) => measur.buildingSectionId === secId
      );
    }

    setFilteredMeasurements(newMeasurements || []);
  };

  const getRowHeader = (id: any, row: any) => {
    if (filteredMeasurements !== undefined && basicInfo !== undefined) {
      const placeInfo = getPlaceInfo(filteredMeasurements, basicInfo, id);
      return (
        <div className="space-header">
          <p>{placeInfo.space}</p>
          <p>{placeInfo.building}</p>
          <p>
            {placeInfo.place?.floor}
            {t(`${translations.projects}:Floors Dropdown`)}
          </p>
          <p>{placeInfo.section}</p>
        </div>
      );
    }
    return null;
  };

  const getStatusName = (statusEng: any, row: any) => {
    return t(`${translations.projects}:status.${statusEng}`);
  };

  const addSelectedId = (id: number) => {
    const ref = idsRef.current;
    if (ref.includes(id)) {
      const index = ref.findIndex((i) => i === id);
      const list = [...ref];
      list.splice(index, 1);
      idsRef.current = list;
      setSelectedIds(list);
    } else {
      idsRef.current = [...ref, id];
      setSelectedIds([...ref, id]);
    }
  };

  const getCheckbox = (id: any, row: any) => {
    return (
      <Input
        type="checkbox"
        className="checkbox-table"
        onClick={() => addSelectedId(row.id)}
      />
    );
  };

  const goToEdit = (placeId: number) => {
    setPlaceId(placeId);
    setSection(PROJECT_MEASUREMENT_ADD_MEASUREMENT);
  };

  const getTotalSquares = (cell: any, row: any) => {
    const squares = getAllSquares(row);
    return (
      <div>
        {squares} {t(`${translations.common}:units.m2`)}
        <EditIcon className="squares-edit" onClick={() => goToEdit(row.id)} />
      </div>
    );
  };

  const getPositionName = (positionId: any, row: any) => {
    return basicInfo?.positions.find((pos) => pos.id === positionId)?.name;
  };

  useEffect(() => {
    const getMeasurements = async () => {
      try {
        const list = await apiProjects.places.get(projectId);
        setMeasurementsRaw(list);
        setFilteredMeasurements(list);
      } catch (error) {
        // define action
      }
    };
    const getBasicInfo = async () => {
      const result: IBasicInfoGet = await apiProjects.measurementCard.get(
        projectId
      );
      setBasicInfo(result);
      getMeasurements().catch((e) => console.log(e));
    };
    getBasicInfo().catch((e) => console.log(e));
  }, [projectId, modalSaved]);

  const expandRow = {
    renderer: (row: any) => {
      const measurementRaw = filteredMeasurements?.find(
        (measurement) => measurement.id === row.id
      );

      return (
        <div className="subtable">
          <Subtable
            columns={getPlaceSubColumns(
              getPositionName,
              getMillimiterUnit,
              getSquares
            )}
            data={measurementRaw ? measurementRaw.measurements : []}
            keyField="id"
          />
        </div>
      );
    },
  };

  return (
    <div>
      <Row>
        <Col>
          <div className="header-text title-spacing">
            {t(`${translations.projects}:tabs.measurement-card`).toUpperCase()}
          </div>
        </Col>
        <Col className="header-add-btn button-margin-top">
          <PrimaryButton
            className="buttons-gap"
            onClick={() => setSection(PROJECT_MEASUREMENT_BASIC_INFO)}
          >
            {t(
              `${translations.projects}:Measurement Basic Information`
            ).toLocaleUpperCase()}
          </PrimaryButton>
          <PrimaryButton
            onClick={() => setSection(PROJECT_MEASUREMENT_ADD_MEASUREMENT)}
          >
            {t(`${translations.projects}:Add Measurement`).toLocaleUpperCase()}
          </PrimaryButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchBars
            basicInfo={basicInfo}
            maxFloors={maxFloors}
            filterMeasurements={filterMeasurements}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BasicTableExpandable
            tableClasses="non-fixed-table"
            columns={getPlaceColumns(
              colTitles,
              getRowHeader,
              getTotalSquares,
              getCheckbox,
              getStatusName
            )}
            data={filteredMeasurements}
            expandRow={expandRow}
            keyField="id"
          />
        </Col>
      </Row>
      {selectedIds.length !== 0 ? (
        <Status
          modalSaved={modalSaved}
          projectId={projectId}
          setModalSaved={setModalSaved}
          selectedIds={selectedIds}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default ProjectMeasurementList;
