import IGlassConfiguratorProduct from 'interfaces/products/IGlassConfiguratorProduct';
import getTargetValue from 'services/forms/getTargetValue';
import {
  KGM_KEY,
  LENGTH_KEY,
  NAME_KEY,
  PAINTING_AREA_KEY,
  PAINTING_1_KEY,
  PAINTING_2_KEY,
  PAINTING_3_KEY,
  PAINTING_4_KEY,
  WAREHOUSE_KEY,
  PURCHASE_PRICE_KEY,
  PURCHASE_UNIT_KEY,
  SUPPLIER_ID_KEY,
  IMAGE_ID_KEY,
  WASTE_KEY,
} from './glassConfiguratorKeyConstants';

const updateGlassConfiguratorProduct = (
  productsFormValues: IGlassConfiguratorProduct[],
  e: any,
  index: number
): IGlassConfiguratorProduct[] => {
  const newValues = [...productsFormValues];
  const type = e.target.name;
  const value = getTargetValue(e);
  if (type === NAME_KEY) {
    newValues[index].name = value;
  } else if (type === LENGTH_KEY) {
    newValues[index].length = value;
  } else if (type === KGM_KEY) {
    newValues[index].kgM = value;
  } else if (type === WAREHOUSE_KEY) {
    newValues[index].warehouse = value;
  } else if (type === PURCHASE_PRICE_KEY) {
    newValues[index].purchasePrice = value;
  } else if (type === PURCHASE_UNIT_KEY) {
    newValues[index].purchaseUnit = value;
  } else if (type === PAINTING_AREA_KEY) {
    newValues[index].paintingArea = value;
  } else if (type === PAINTING_1_KEY) {
    newValues[index].painting1 = value;
  } else if (type === PAINTING_2_KEY) {
    newValues[index].painting2 = value;
  } else if (type === PAINTING_3_KEY) {
    newValues[index].painting3 = value;
  } else if (type === PAINTING_4_KEY) {
    newValues[index].painting4 = value;
  } else if (type === SUPPLIER_ID_KEY) {
    newValues[index].supplierId = value === '' ? null : parseInt(value, 10);
  } else if (type === IMAGE_ID_KEY) {
    newValues[index].imageId = value === '' ? null : value;
  } else if (type === WASTE_KEY) {
    newValues[index].waste = value;
  }

  return newValues;
};

export default updateGlassConfiguratorProduct;
