import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import IProjectGet from 'api/interfaces/projects/IProjectGet';
import { Row, Col, FormGroup } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import PageContainer from 'components/layout/PageContainer';
import SearchDropdown from 'components/layout/forms/SearchDropdown';
import getCustomerDropdown from 'services/offers/getCustomerDropdown';
import formatFinnishDate from 'services/dates/formatFinnishDate';
import BasicMaterialTable from '../layout/tables/BasicMaterialTable';

import apiProjects from '../../api/projects';
import customerApi from '../../api/contacts';
import translations from '../../services/translations/translations';
import { BASIC_INFORMATION } from './tabTypes';

import 'style/projects/Projects.css';

const searchCustomer = async (inputValue: string) =>
  getCustomerDropdown(
    await customerApi.get({
      limit: 100,
      offset: 0,
      ownOrganizationUnit: true,
      search: inputValue,
      type: 'customer',
    })
  );

const FilterComponent = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function,
  companyDefaultValue: string,
  placeHolderText: string
) => (
  <SearchDropdown
    value={companyDefaultValue}
    required
    placeholder={placeHolderText}
    className="text-field search-field"
    name="customer"
    onChange={onChange}
    loadOptions={searchCustomer}
  />
);

const Projects = (): JSX.Element | null => {
  const history = useHistory();
  const { t } = useTranslation([translations.projects, translations.common]);
  const [projects, setProjects] = useState<IProjectGet[]>();
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [offset, setOffset] = useState(0);
  const [totalSize, setTotalSize] = useState<number>();
  const [selectedRow, setSelectedRow] = useState<number>();
  const selectedProject =
    projects !== undefined && selectedRow !== undefined
      ? projects[selectedRow]
      : undefined;
  const [searchTerms, setSearchTerms] = useState<string>('');
  const [customer, setCustomer] = useState<{
    label: string;
    value: string;
  }>();
  const PROJECTS_FILTER_SESSION = 'projects-filter-local-storage';

  useEffect(() => {
    if (selectedRow !== undefined) {
      history.push(`/projects/${selectedProject?.id}/${BASIC_INFORMATION}`);
    }
  }, [history, selectedProject, selectedRow]);

  const getColumnFieldFromFilter = (query: any, fieldName: string): any => {
    return query.filters?.find((f: any) => f.column.field === fieldName)?.value;
  };

  const getProjects = async (query: any) => {
    try {
      const name = getColumnFieldFromFilter(query, 'name');
      const customer = getColumnFieldFromFilter(
        query,
        'customerContacts[0].contact.companyName'
      );
      const start = getColumnFieldFromFilter(query, 'start');
      const end = getColumnFieldFromFilter(query, 'end');

      const list = await apiProjects.get({
        limit: sizePerPage,
        offset,
        search: name,
        customerId: customer,
        start,
        end,
      });
      const count = await apiProjects.count({
        search: name,
        customerId: customer,
        start,
        end,
      });

      return {
        data: list,
        page: query.page,
        totalCount: count.count,
      };
    } catch (error) {
      return {};
    }
  };

  const colTitles = {
    name: t(`${translations.projects}:Project`),
    customer: t(`${translations.projects}:Customer`),
    start: t(`${translations.projects}:Start date`),
    end: t(`${translations.projects}:End date`),
  };

  const formatDate = (cell: string, row: any) => {
    return formatFinnishDate(cell);
  };

  const getDefaultFilter = (field: string) => {
    const storedFilters = window.localStorage.getItem(PROJECTS_FILTER_SESSION);
    if (storedFilters == null) {
      return null;
    }

    const lastUsedFilters = JSON.parse(storedFilters);
    if (lastUsedFilters == null || lastUsedFilters.length <= 0) {
      return null;
    }

    const result = lastUsedFilters.find(
      (lastUsedfilter: any) => lastUsedfilter.column.field === field
    );

    return result != null ? result.value : null;
  };

  const companyDefaultValue = getDefaultFilter(
    'customerContacts[0].contact.companyName'
  );

  const columns = [
    {
      field: 'name',
      title: colTitles.name,
      defaultFilter: getDefaultFilter('name'),
    },
    {
      field: 'customerContacts[0].contact.companyName',
      title: colTitles.customer,
      defaultFilter: companyDefaultValue,
      filterComponent: (props: any) =>
        FilterComponent(
          (event: any) => {
            props.onFilterChanged(props.columnDef.tableData.id, event?.value);
          },
          companyDefaultValue,
          t(`${translations.projects}:Customer`)
        ),
    },
    {
      field: 'start',
      title: colTitles.start,
      type: 'date',
      defaultFilter: getDefaultFilter('start'),
      dateSetting: { locale: 'fi-FI' },
    },
    {
      field: 'end',
      title: colTitles.end,
      type: 'date',
      defaultFilter: getDefaultFilter('end'),
      dateSetting: { locale: 'fi-FI' },
    },
  ];

  const handleTableChange = (type: any, { page, sizePerPage }: any) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    setOffset((page - 1) * sizePerPage);
  };

  const handleCustomer = (e: any) => {
    if (e === null) {
      setCustomer({ label: '', value: '' });
    } else {
      setCustomer({ label: e.label, value: e.value });
    }
  };

  return (
    <PageContainer>
      <Row className="row-content">
        <Col xs={9}>
          <p className="header-text">{t(`${translations.common}:Projects`)}</p>
        </Col>
      </Row>
      <div className="basic-table table-background-offers">
        <Row>
          <Col>
            <BasicMaterialTable
              columns={columns}
              fetchData={getProjects}
              sizePerPage={sizePerPage}
              setSelectedRow={setSelectedRow}
              localStorageTag={PROJECTS_FILTER_SESSION}
            />
          </Col>
        </Row>
      </div>
    </PageContainer>
  );
};

export default Projects;
