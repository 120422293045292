import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useMsal } from '@azure/msal-react';

import translations from '../../services/translations/translations';
import { RootState } from '../../store/store';
import { setAccessToken as setAccessTokenAction } from '../../store/users/actions';
import '../../style/layout/form/SidebarLink.css';

const Logout = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const { t } = useTranslation([translations.common]);

  const user = useSelector((state: RootState) => state.users.current);

  const logout = async () => {
    dispatch(setAccessTokenAction(null));
    await instance.logout({ account: instance.getActiveAccount() });
  };

  if (user === null) {
    return null;
  }

  return (
    <div
      className="sidebar-link"
      style={{ marginTop: 'auto' }}
      onClick={async () => {
        await logout();
      }}
      aria-hidden
    >
      <p className="sidebar-link-text">{t('Logout')}</p>
    </div>
  );
};

export default Logout;
