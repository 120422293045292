import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import apiProjects from 'api/projects';
import downloadFile from 'services/files/downloadFile';
import IFile from 'interfaces/forms/IFile';
import translations from 'services/translations/translations';

import 'style/projects/projectMeasurementTab/addMeasurement/PlaceImages.css';
import { useDropzone } from 'react-dropzone';
import ImageWithPreviewInput from '../../layout/forms/ImageWithPreviewInput';
import apiFiles from '../../../api/files';

const PlaceImage = ({
  imageId,
  name,
  setImageId,
}: {
  imageId: string | null;
  name: string;
  setImageId: any;
}): JSX.Element => {
  const { t } = useTranslation([translations.common]);
  const [file, setFile] = useState<any>();

  const dowloadAndSetFile = async () => {
    if (imageId == null) {
      return;
    }
    const response = await apiProjects.files.get(imageId);
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    });

    setFile({
      data: blob,
      headers: response.headers,
      id: imageId,
    });
  };

  const onDrop = (acceptedFiles: any) => {
    // setLoadingFiles(acceptedFiles);

    const handleFileSave = async (loadingFile: any, index: number) => {
      await apiFiles.post(loadingFile, 'glass-configurator-product');

      // setLoadingFiles(
      //     loadingFiles.filter(
      //         (existingLoadingFile: any) => existingLoadingFile !== loadingFile
      //     )
      // );

      // addFile({
      //   file: loadingFile,
      //   id: result.id.toString(),
      //   name: loadingFile.name,
      //   key: getNextObjectKey(files, 'key') + index,
      // });
    };

    let acceptedFileIndex = 0;
    for (const acceptedFile of acceptedFiles) {
      handleFileSave(acceptedFile, acceptedFileIndex);
      acceptedFileIndex++;
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const addFile = (f: IFile) => {
    setImageId(f?.id);
  };

  const removeFile = async (f: IFile) => {
    setImageId(null);
  };

  const onDownloadFile = async (f: IFile) => {
    if (f.id === null) {
      return;
    }

    const response = await apiProjects.files.get(f.id);
    downloadFile(response.data, response.headers);
  };

  useEffect(() => {
    if (imageId != null) {
      dowloadAndSetFile();
    } else {
      setFile(null);
    }
  }, [imageId]);

  return (
    <div className="place-image">
      <ImageWithPreviewInput
        setFile={addFile}
        downloadFile={onDownloadFile}
        fileType="glass-configurator-product"
        file={file}
        id="place-images"
        removeFile={removeFile}
      />
      <div className="clearfix" />
    </div>
  );
};

export default PlaceImage;
