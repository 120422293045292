const NAME = 'name';
const LENGTH = 'length';
const WASTE = 'waste';
const KGM = 'kg-in-m';
const WAREHOUSE = 'warehouse';
const PURCHASE_PRICE = 'purchase-price';
const PAINTING_AREA = 'painting-area';
const PAINTING_1 = 'painting-1';
const PAINTING_2 = 'painting-2';
const PAINTING_3 = 'painting-3';
const PAINTING_4 = 'painting-4';

export {
  NAME,
  LENGTH,
  WASTE,
  KGM,
  WAREHOUSE,
  PURCHASE_PRICE,
  PAINTING_1,
  PAINTING_2,
  PAINTING_3,
  PAINTING_4,
  PAINTING_AREA,
};
