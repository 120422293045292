import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiProducts from 'api/products';
import getItemConfiguration from 'services/products/configurations/getItemConfiguration';
import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';
import IProductConfigurationSummary from 'interfaces/products/IProductConfigurationSummary';
import translations from 'services/translations/translations';
import { addMessage } from 'store/messages/actions';
import { finishLoading, startLoading } from 'store/loading/actions';

import {
  ACCOMMODATION,
  INSTALLATION as INSTALLATION_TYPE_INSTALLATION,
} from 'services/enums/offers/installationTypes';

import {
  INSTALLATION,
  PRODUCT,
} from 'services/products/productConfigurationItemTypes';

import getAddItemTitle from './src/item/getAddItemTitle';
import getDefaultInstallationItem from './src/item/getDefaultInstallationItem';
import getDefaultItem from './src/item/getDefaultItem';
import getDefaultProductItem from './src/item/getDefaultProductItem';
import Item from './src/item/Item';
import validItem from './src/item/validItem';
import { OTHER_EXPENSE } from './src/installationTypes';

const CreateItem = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { configurationId, itemId } = useParams<{
    configurationId: string | undefined;
    itemId: string | undefined;
  }>();

  const { type } = useParams<{ type: string }>();
  const { t } = useTranslation([translations.common, translations.products]);

  const numericItemId = itemId === undefined ? null : parseInt(itemId, 10);

  const [parentItem, setParentItem] =
    useState<IProductConfigurationItem | null>(null);

  const [configurationName, setConfigurationName] = useState('');

  const [item, setItem] = useState<IProductConfigurationItem>(
    (function getDefault() {
      if (type === INSTALLATION) {
        return getDefaultInstallationItem(
          INSTALLATION,
          INSTALLATION_TYPE_INSTALLATION
        );
      }

      if (type === OTHER_EXPENSE) {
        return getDefaultInstallationItem(INSTALLATION, ACCOMMODATION);
      }

      if (type === PRODUCT) {
        return getDefaultProductItem(type);
      }

      return getDefaultItem(type);
    })()
  );

  const getNumericConfigurationId = (): null | number => {
    if (configurationId !== undefined) {
      return parseInt(configurationId, 10);
    }

    if (parentItem === null) {
      return null;
    }

    const parentItemConfiguration = getItemConfiguration(parentItem);
    if (parentItemConfiguration === null) {
      return null;
    }

    return parentItemConfiguration.id;
  };

  const initializeConfiguration = async () => {
    if (configurationId !== undefined) {
      const numericConfigurationId = getNumericConfigurationId();
      if (numericConfigurationId === null) {
        return;
      }

      dispatch(startLoading('product-configuration-summary'));
      const configuration: IProductConfigurationSummary =
        await apiProducts.configurations.summary.get(numericConfigurationId);
      dispatch(finishLoading('product-configuration-summary'));

      setConfigurationName(configuration.name);
    } else if (numericItemId !== null) {
      dispatch(startLoading('product-configuration-item'));
      const parentItemResult: IProductConfigurationItem =
        await apiProducts.configurations.items.get(numericItemId);
      dispatch(finishLoading('product-configuration-item'));

      setParentItem(parentItemResult);

      const parentItemConfiguration = getItemConfiguration(parentItemResult);
      if (parentItemConfiguration !== null) {
        setConfigurationName(parentItemConfiguration.name);
      }
    }
  };

  useEffect(() => {
    initializeConfiguration();
  }, [configurationId]);

  const save = async () => {
    if (!validItem(item, type)) {
      dispatch(addMessage(t(`${translations.common}:Please fill all fields`)));
      return;
    }

    try {
      if (configurationId !== undefined) {
        await apiProducts.configurations.items.post(
          getNumericConfigurationId() as number,
          item
        );

        history.push(`/products/configurations/${configurationId}`);
      } else if (numericItemId !== null && parentItem !== null) {
        await apiProducts.configurations.items.postForParent(
          numericItemId,
          item
        );

        const parentItemConfiguration = getItemConfiguration(parentItem);
        if (parentItemConfiguration !== null) {
          history.push(
            `/products/configurations/${parentItemConfiguration.id}`
          );
        }
      }
    } catch (e) {
      dispatch(addMessage(t(`${translations.common}:Saving failed`)));
    }
  };

  return (
    <Item
      configurationId={getNumericConfigurationId()}
      configurationName={configurationName}
      item={item}
      save={save}
      setItem={setItem}
      title={getAddItemTitle(parentItem, t)}
    />
  );
};

export default CreateItem;
