import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import apiProducts from 'api/products';
import Checkbox from 'components/layout/forms/Checkbox';
import formatFloatForDisplay from 'services/numbers/formatFloatForDisplay';
import getContributionMarginPerUnit from 'services/products/calculations/getContributionMarginPerUnit';
import getContributionMarginRatio from 'services/products/calculations/getContributionMarginRatio';
import getContributionMarginTotal from 'services/products/calculations/getContributionMarginTotal';
import getExpenseTotal from 'services/products/calculations/getExpenseTotal';
import getFormStateHandler from 'services/forms/getFormStateHandler';
import getSellingPriceTotal from 'services/products/calculations/getSellingPriceTotal';
import getSubproductTotalExpense from 'services/products/calculations/getSubproductTotalExpense';
import IProduct from 'api/interfaces/products/IProductGet';
import NumberWithUnitInputField from 'components/layout/forms/NumberWithUnitInputField';
import NumberWithDropdownInputField from 'components/layout/forms/NumberWithDropdownInputField';
import SearchDropdown from 'components/layout/forms/SearchDropdown';
import TextInputField from 'components/layout/forms/TextInputField';
import TrashIcon from 'components/layout/icons/TrashIcon';
import translations from 'services/translations/translations';
import { APPROVED } from 'services/enums/offers/Statuses';

import {
  DETAIL_CUSTOM_PRODUCT_NAME,
  DETAIL_HAS_CUSTOM_PRODUCT_NAME,
  EXPENSE,
  EXPENSE_UNIT,
  PRODUCT_ID,
  QUANTITY,
  QUANTITY_UNIT,
  SELLING_PRICE,
  SELLING_PRICE_UNIT,
  WASTE_PERCENTAGE,
} from 'services/products/productConfigurationAttributeTypes';

import IValueWithLabel from 'interfaces/forms/IValueWithLabel';
import getSellingPriceUnitOptions from '../../../../services/getSellingPriceUnitOptions';
import getUnitOptions from '../../../../services/getUnitOptions';
import IDetail from '../../IDetail';

const SubproductDetail = ({
  detail,
  initialStatus,
  removeDetail,
  setDetail,
  subproductQuantity,
}: {
  detail: IDetail;
  initialStatus: null | string;
  removeDetail: () => void;
  setDetail: (updatedField: string) => (detail: IDetail) => void;
  subproductQuantity: number;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.common, translations.offers]);
  const units = useSelector((state: any) => state.products.units);

  useEffect(() => {
    const { expenseUnit } = detail;

    const sellingPriceUnit =
      detail.sellingPriceUnit === '' ||
      detail.sellingPriceUnit === detail.quantityUnit
        ? detail.sellingPriceUnit
        : detail.quantityUnit;

    const changed =
      detail.expenseUnit !== expenseUnit ||
      detail.sellingPriceUnit !== sellingPriceUnit;

    if (!changed) {
      return;
    }

    setDetail('')({
      ...detail,
      expenseUnit,
      sellingPriceUnit,
    });
  }, [detail, setDetail]);

  const loadProducts = async (inputValue: string) => {
    const list = (await apiProducts.get(100, 0, inputValue)) as IProduct[];
    return list.map((product: IProduct) => ({
      label: [null, ''].includes(product.displayName)
        ? product.name
        : product.displayName,
      value: product.id,
    }));
  };

  const offerIsApproved = () => initialStatus === APPROVED;

  const sellingPriceUnitOptions = getSellingPriceUnitOptions(
    detail.quantityUnit,
    detail.key.toString(),
    t
  );

  const handleFormState = (fieldType: string) => {
    return getFormStateHandler(detail, setDetail(fieldType));
  };

  const handleProductChange = (product: IValueWithLabel | null) => {
    setDetail(PRODUCT_ID)({
      ...detail,
      product,
    });
  };

  const productSelector = detail.hasCustomProductName ? (
    <TextInputField
      value={detail.customProductName || ''}
      disabled={offerIsApproved()}
      onChange={handleFormState(DETAIL_CUSTOM_PRODUCT_NAME)}
      name="customProductName"
    />
  ) : (
    <SearchDropdown
      className="text-field search-field"
      disabled={offerIsApproved()}
      loadOptions={loadProducts}
      name="product"
      onChange={handleProductChange}
      placeholder={t(`${translations.common}:Search`)}
      required
      value={detail.product}
    />
  );

  const toggleHasCustomProductName = () => {
    const newValue = !detail.hasCustomProductName;
    setDetail(DETAIL_HAS_CUSTOM_PRODUCT_NAME)({
      ...detail,
      hasCustomProductName: newValue,
    });
  };

  const expense = getSubproductTotalExpense(detail);
  const contributionMargin = getContributionMarginPerUnit([], [detail]);
  const expenseTotal = getExpenseTotal([], [detail], subproductQuantity);
  const sellingPriceTotal = getSellingPriceTotal(
    [],
    [detail],
    subproductQuantity
  );
  const contributionMarginTotal = getContributionMarginTotal(
    [],
    [detail],
    subproductQuantity
  );
  const contributionMarginRatio = getContributionMarginRatio([], [detail]);

  const SMALL_COLUMN_CLASS = 'small-column';

  return (
    <Row>
      <Col xs="1" className="mt-n2">
        <Checkbox
          checked={detail.hasCustomProductName}
          label=""
          onChange={toggleHasCustomProductName}
        />
      </Col>
      <Col>{productSelector}</Col>
      <Col>
        <NumberWithDropdownInputField
          disabled={offerIsApproved()}
          dropdownName="quantityUnit"
          dropdownOnChange={handleFormState(QUANTITY_UNIT)}
          dropdownOptions={getUnitOptions(units, t)}
          dropdownValue={detail.quantityUnit}
          numberName="quantity"
          numberOnChange={handleFormState(QUANTITY)}
          numberValue={detail.quantity}
          required
        />
      </Col>
      <Col>
        <NumberWithDropdownInputField
          disabled={offerIsApproved()}
          dropdownDisabled
          dropdownName="expenseUnit"
          dropdownOnChange={handleFormState(EXPENSE_UNIT)}
          dropdownOptions={sellingPriceUnitOptions}
          dropdownValue={detail.expenseUnit}
          numberName="expense"
          numberOnChange={handleFormState(EXPENSE)}
          numberValue={detail.expense}
          required
        />
      </Col>
      <Col xs="1" className={SMALL_COLUMN_CLASS}>
        <NumberWithUnitInputField
          disabled={offerIsApproved()}
          name="wastePercentage"
          onChange={handleFormState(WASTE_PERCENTAGE)}
          required
          unit="%"
          value={detail.wastePercentage}
        />
      </Col>

      <Col xs="1" className={SMALL_COLUMN_CLASS}>
        <NumberWithUnitInputField
          disabled
          unit="€"
          value={formatFloatForDisplay(expense)}
        />
      </Col>

      <Col>
        <NumberWithDropdownInputField
          disabled={offerIsApproved()}
          dropdownDisabled
          dropdownName="sellingPriceUnit"
          dropdownOnChange={handleFormState(SELLING_PRICE_UNIT)}
          dropdownOptions={sellingPriceUnitOptions}
          dropdownValue={detail.sellingPriceUnit}
          numberName="sellingPrice"
          numberOnChange={handleFormState(SELLING_PRICE)}
          numberValue={detail.sellingPrice}
          required
        />
      </Col>

      <Col xs="1" className={SMALL_COLUMN_CLASS}>
        <NumberWithUnitInputField
          disabled
          unit="€"
          value={formatFloatForDisplay(contributionMargin)}
        />
      </Col>

      <Col xs="1" className={SMALL_COLUMN_CLASS}>
        <NumberWithUnitInputField
          disabled
          unit="€"
          value={formatFloatForDisplay(expenseTotal)}
        />
      </Col>

      <Col xs="1" className={SMALL_COLUMN_CLASS}>
        <NumberWithUnitInputField
          disabled
          unit="€"
          value={formatFloatForDisplay(sellingPriceTotal)}
        />
      </Col>

      <Col xs="1" className={SMALL_COLUMN_CLASS}>
        <NumberWithUnitInputField
          disabled
          unit="€"
          value={formatFloatForDisplay(contributionMarginTotal)}
        />
      </Col>

      <Col className={SMALL_COLUMN_CLASS}>
        <NumberWithUnitInputField
          disabled
          unit="%"
          value={formatFloatForDisplay(contributionMarginRatio)}
        />
      </Col>

      <Col className="icon-column pl-2">
        <TrashIcon onClick={removeDetail} />
      </Col>
    </Row>
  );
};

export default SubproductDetail;
