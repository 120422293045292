import IProductConfigurationAttribute from 'api/interfaces/products/IProductConfigurationAttribute';
import IProductConfigurationItem from 'api/interfaces/products/IProductConfigurationItem';
import {
  EXPENSE_UNIT,
  QUANTITY_UNIT,
} from '../../../../../services/products/productConfigurationAttributeTypes';

const attributeValueUpdater =
  (
    item: IProductConfigurationItem,
    setItem: (item: IProductConfigurationItem) => void
  ) =>
  (attributes: IProductConfigurationAttribute[]): void => {
    const newAttributes: IProductConfigurationAttribute[] = [];
    const updatedAttributes: IProductConfigurationAttribute[] = [];

    for (const updatedAttribute of attributes) {
      const existingAttribute = item.attributes.find(
        (attribute) => attribute.type === updatedAttribute.type
      );

      if (updatedAttribute.type === QUANTITY_UNIT) {
        const expanseUnit = item.attributes.find(
          (attribute) => attribute.type === EXPENSE_UNIT
        );
        if (expanseUnit != null) {
          expanseUnit.value = updatedAttribute.value;
        }
      }

      if (existingAttribute === undefined) {
        newAttributes.push(updatedAttribute);
        continue;
      }

      updatedAttributes.push(updatedAttribute);
    }

    setItem({
      ...item,
      attributes: [
        ...item.attributes.map((attribute) => {
          const updatedAttribute = updatedAttributes.find(
            (a) => a.type === attribute.type
          );

          return updatedAttribute === undefined ? attribute : updatedAttribute;
        }),
        ...newAttributes,
      ],
    });
  };

export default attributeValueUpdater;
