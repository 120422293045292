import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import apiInstallation from 'api/installationPrices';
import IInstallationPriceGet from 'api/interfaces/installationPrices/IInstallationPriceGet';
import IInstallationPricePost from 'api/interfaces/installationPrices/IInstallationPricePost';
import BasicFormContainer from 'components/layout/BasicFormContainer';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import NumberWithDropdownInputField from 'components/layout/forms/NumberWithDropdownInputField';
import TextInputField from 'components/layout/forms/TextInputField';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import PageContainer from 'components/layout/PageContainer';
import getInstallationCategoryOptions from 'components/offers/services/getInstallationCategoryOptions';
import getProductUnitOptions from 'components/offers/src/offerProduct/getProductUnitOptions';

import getTargetValue from 'services/forms/getTargetValue';
import replaceCommas from 'services/numbers/replaceCommas';
import translations from 'services/translations/translations';

import { addConfirm, addMessage } from 'store/messages/actions';
import {
  fetchInstallationCategories,
  fetchUnits,
} from 'store/products/actions';

import 'style/products/Product.css';

const InstallationPrice = (): JSX.Element | null => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation([translations.products, translations.common]);
  const [formState, setFormState] = useState({
    name: '',
    category: '',
    sellingPrice: '',
    sellingPriceUnit: '',
  });

  const installationCategories = useSelector(
    (state: any) => state.products.installationCategories
  );
  const units = useSelector((state: any) => state.products.units);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInstallationCategories());
    dispatch(fetchUnits());
  }, []);

  const goPreviousPage = () => {
    history.push('/products');
  };

  const deleteInstallationPrice = async () => {
    try {
      const result = await apiInstallation.delete(id);
      if (result !== undefined && result.success === true) {
        dispatch(addMessage(t(`${translations.common}:Delete Success`)));
        goPreviousPage();
      } else {
        dispatch(addMessage(t(`${translations.common}:Delete Failure`)));
      }
    } catch (error) {
      dispatch(addMessage(t(`${translations.common}:Delete Failure`)));
    }
  };

  const submitInstallationPrice = () => {
    const validInstallationPrice =
      formState.name !== '' &&
      formState.category !== '' &&
      formState.sellingPrice !== '' &&
      formState.sellingPriceUnit !== '';

    if (!validInstallationPrice) {
      dispatch(addMessage(t(`${translations.common}:Fill all fields`)));
      return;
    }

    const product: IInstallationPricePost = {
      name: formState.name,
      category: formState.category,
      sellingPrice: replaceCommas(formState.sellingPrice),
      sellingPriceUnit: formState.sellingPriceUnit,
    };

    const submit = async () => {
      let result;
      if (id !== undefined) {
        try {
          await apiInstallation.put(id, product);
          dispatch(addMessage(t(`${translations.common}:Saving succeeded`)));
        } catch (error) {
          dispatch(addMessage(t(`${translations.common}:Saving failed`)));
        }
      } else {
        try {
          result = await apiInstallation.post(product);
          dispatch(addMessage(t(`${translations.common}:Saving succeeded`)));
          history.push(`/products/installation-prices/${result.id}`);
        } catch (error) {
          dispatch(addMessage(t(`${translations.common}:Saving failed`)));
        }
      }
    };
    submit().catch((e) => console.log(e));
  };

  const handleFormState = (e: any) => {
    setFormState({
      ...formState,
      [e.target.name]: getTargetValue(e),
    });
  };

  useEffect(() => {
    if (id !== undefined) {
      const getInstallation = async () => {
        try {
          const result: IInstallationPriceGet =
            await apiInstallation.getInstallation(id);
          if (result !== undefined) {
            setFormState({
              ...formState,
              name: result.name,
              category: result.category,
              sellingPrice: result.sellingPrice.toString(),
              sellingPriceUnit: result.sellingPriceUnit,
            });
          }
        } catch (error) {
          // define action
        }
      };
      getInstallation().catch((e) => console.log(e));
    }
  }, [id]);

  const removeClicked = () => {
    dispatch(
      addConfirm({
        callback: deleteInstallationPrice,
        message: t(`${translations.products}:Delete installation confirmation`),
      })
    );
  };

  return (
    <PageContainer>
      <BasicFormContainer>
        <Form>
          <Row>
            <Col>
              <TextInputField
                value={formState.name}
                label={`${t(`${translations.products}:Name`)} *`}
                required
                id="name"
                name="name"
                onChange={handleFormState}
              />
            </Col>
            <Col>
              <label className="label" htmlFor="category">
                {t(`${translations.common}:Category`)}
              </label>
              <SelectInputFieldControlled
                id="category"
                name="category"
                onChange={handleFormState}
                options={getInstallationCategoryOptions(
                  installationCategories,
                  t
                )}
                required
                value={formState.category}
              />
            </Col>
            <Col>
              <NumberWithDropdownInputField
                dropdownName="sellingPriceUnit"
                dropdownOnChange={handleFormState}
                dropdownOptions={getProductUnitOptions(units, t, true)}
                dropdownValue={formState.sellingPriceUnit}
                id="quantity-and-unit"
                label={`${t(`${translations.common}:Price`)} *`}
                numberName="sellingPrice"
                numberOnChange={handleFormState}
                numberValue={formState.sellingPrice}
                required
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <PrimaryButton onClick={goPreviousPage}>
                {t(`${translations.common}:Back`)}
              </PrimaryButton>

              {id !== undefined ? (
                <PrimaryButton
                  className="add-button-margin"
                  onClick={removeClicked}
                >
                  {t(`${translations.common}:Remove`)}
                </PrimaryButton>
              ) : (
                ''
              )}

              <PrimaryButton
                className="add-button-margin"
                onClick={submitInstallationPrice}
              >
                {t(`${translations.products}:Submit`)}
              </PrimaryButton>
            </Col>
          </Row>
        </Form>
      </BasicFormContainer>
    </PageContainer>
  );
};

export default InstallationPrice;
