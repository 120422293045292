import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import 'style/layout/form/TextInputField.css';

const TextInputField = ({
  bsSize,
  className,
  disabled,
  id,
  label,
  labelClassName,
  name,
  onChange,
  required,
  title,
  value,
}: {
  bsSize?: 'lg' | 'sm';
  className?: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  labelClassName?: string;
  name?: string;
  onChange?: any;
  required?: boolean;
  title?: string;
  value: string | number;
}): JSX.Element => (
  <FormGroup>
    {label === undefined ? null : (
      <Label className={`${labelClassName} label`} for={id}>
        {label}
      </Label>
    )}
    <Input
      bsSize={bsSize}
      className={`${className} text-field`}
      disabled={disabled}
      id={id}
      name={name}
      onChange={onChange}
      required={required === undefined ? false : required}
      title={title}
      type="text"
      value={value === undefined ? '' : value}
    />
  </FormGroup>
);

export default TextInputField;
