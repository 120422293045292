export default (t: (key: string) => string, location: string) =>
  (a: null | string, b: null | string): number => {
    if (a === null && b == null) {
      return 0;
    }

    if (a === null) {
      return -1;
    }

    if (b === null) {
      return 1;
    }

    const translatedA = t(`${location}:${a}`).toLowerCase();
    const translatedB = t(`${location}:${b}`).toLowerCase();

    if (translatedA === translatedB) {
      return 0;
    }

    return translatedA < translatedB ? -1 : 1;
  };
