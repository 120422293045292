import IGlassConfiguratorProduct from 'interfaces/products/IGlassConfiguratorProduct';
import IMeasurement from 'interfaces/products/IMeasurement';
import IPrice from 'interfaces/products/IPrice';
import IProductPatch from 'api/interfaces/products/IProductPatch';
import { EUR } from 'services/enums/currencies';
import { KG, M, M2, MM, MM2 } from 'services/enums/units';

import formatMeasurementToNumber from './formatMeasurementToNumber';
import formatPriceToNumber from './formatPriceToNumber';
import {
  KGM,
  LENGTH,
  PAINTING_AREA,
  PAINTING_1,
  PAINTING_2,
  PAINTING_3,
  PAINTING_4,
  WAREHOUSE,
  PURCHASE_PRICE,
  WASTE,
} from './glassConfiguratorFieldNames';

const getProductPatchMeasurements = (
  product: IGlassConfiguratorProduct
): IMeasurement[] => {
  const tempMeasurements: IMeasurement[] = [
    {
      value: product.kgM !== undefined ? product.kgM : 0,
      siUnit: KG,
      type: KGM,
    },
    {
      value: product.length !== undefined ? product.length : 0,
      siUnit: M,
      type: LENGTH,
    },
    {
      value: product.waste !== undefined ? product.waste : 0,
      siUnit: MM,
      type: WASTE,
    },
    {
      value: product.paintingArea !== undefined ? product.paintingArea : 0,
      siUnit: MM,
      type: PAINTING_AREA,
    },
  ];
  return formatMeasurementToNumber(tempMeasurements);
};

const getProductPatchPrices = (
  product: IGlassConfiguratorProduct
): IPrice[] => {
  const tempPrices: IPrice[] = [
    {
      value: product.painting1 !== undefined ? product.painting1 : 0,
      currency: EUR,
      siUnit: M,
      type: PAINTING_1,
    },
    {
      value: product.painting2 !== undefined ? product.painting2 : 0,
      currency: EUR,
      siUnit: M,
      type: PAINTING_2,
    },
    {
      value: product.painting3 !== undefined ? product.painting3 : 0,
      currency: EUR,
      siUnit: M,
      type: PAINTING_3,
    },
    {
      value: product.painting4 !== undefined ? product.painting4 : 0,
      currency: EUR,
      siUnit: M,
      type: PAINTING_4,
    },
    {
      value: product.purchasePrice !== undefined ? product.purchasePrice : 0,
      currency: EUR,
      siUnit: product.purchaseUnit,
      type: PURCHASE_PRICE,
    },
    {
      value: product.warehouse !== undefined ? product.warehouse : 0,
      currency: EUR,
      siUnit: M,
      type: WAREHOUSE,
    },
  ];
  return formatPriceToNumber(tempPrices);
};

const getProductPatch = (
  product: IGlassConfiguratorProduct
): IProductPatch => ({
  name: product.name !== undefined ? product.name : '',
  categories: product.categories,
  supplierId: product.supplierId,
  measurements: getProductPatchMeasurements(product),
  prices: getProductPatchPrices(product),
  productIds: product.productIds,
  tags: product.tags,
  imageId: product.imageId != null ? product.imageId : null,
  id: product.id !== undefined ? product.id : null,
});

export default getProductPatch;
