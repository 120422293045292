import React from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import TextInputField from 'components/layout/forms/TextInputField';
import translations from 'services/translations/translations';
import { addMessage } from 'store/messages/actions';
import { APPROVED } from 'services/enums/offers/Statuses';
import { RIGHT } from 'services/enums/sides';

import IVersion from '../IVersion';
import OfferText from './OfferText';

const Header = ({
  activeVersionIndex,
  initialStatus,
  offerText,
  onOfferTextChanged,
  setActiveVersionIndex,
  setVersions,
  versions,
}: {
  activeVersionIndex: number;
  initialStatus: null | string;
  offerText: string;
  onOfferTextChanged: (value: string) => void;
  setActiveVersionIndex: (index: number) => void;
  setVersions: (versions: IVersion[]) => void;
  versions: IVersion[];
}): JSX.Element => {
  const { t } = useTranslation([translations.common, translations.offers]);
  const dispatch = useDispatch();

  const onVersionNameChange = (event: any) =>
    setVersions(
      versions.map((version, index) =>
        index === activeVersionIndex
          ? { ...version, name: event.target.value }
          : version
      )
    );

  const remove = () => {
    setActiveVersionIndex(
      activeVersionIndex - 1 >= 0 ? activeVersionIndex - 1 : 0
    );

    setVersions(versions.filter((version, i) => i !== activeVersionIndex));

    dispatch(
      addMessage(t(`${translations.calculationWindow}:Version removed`))
    );
  };

  const setPrimary = () =>
    setVersions(
      versions.map((version, index) => ({
        ...version,
        isPrimary: index === activeVersionIndex,
      }))
    );

  const canVersionBeDeleted = () =>
    !offerIsApproved() &&
    versions.length > 1 &&
    !versions[activeVersionIndex].isPrimary;

  const offerIsApproved = () => initialStatus === APPROVED;

  return (
    <>
      <h2>{t(`${translations.calculationWindow}:Version details`)}</h2>
      <Row>
        <Col>
          <TextInputField
            disabled={offerIsApproved()}
            id="offer-product-version-name"
            label={t(`${translations.common}:Name`)}
            name="offer-product-version-name"
            onChange={onVersionNameChange}
            required
            value={versions[activeVersionIndex].name}
          />
        </Col>
        <Col>
          {versions[activeVersionIndex].isPrimary ? null : (
            <>
              {offerIsApproved() ? null : (
                <PrimaryButton
                  className="set-primary-offer-product-version"
                  onClick={setPrimary}
                >
                  {t(`${translations.calculationWindow}:Set primary`)}
                </PrimaryButton>
              )}
            </>
          )}
        </Col>
        <Col className="col-6" m="1" xs="0">
          <OfferText
            initialStatus={initialStatus}
            offerText={offerText}
            onOfferTextChanged={onOfferTextChanged}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <PrimaryButton
            className="button-one-line"
            float={RIGHT}
            disabled={!canVersionBeDeleted()}
            onClick={remove}
          >
            {t(
              `${translations.calculationWindow}:Remove version`
            ).toLocaleUpperCase()}
          </PrimaryButton>
        </Col>
      </Row>
    </>
  );
};

export default Header;
