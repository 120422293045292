import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Label, Col, Row } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';

import MultiSelectDropdown from 'components/layout/forms/MultiSelectDropdown';
import ModalConfirm from 'components/layout/modals/ModalConfirm';
import PageContainer from 'components/layout/PageContainer';
import { fetchOrganizationUnits } from 'store/users/actions';
import IOrganizationUnit from 'interfaces/users/IOrganizationUnit';
import ModalAccept from 'components/layout/modals/ModalAccept';
import TextInputField from 'components/layout/forms/TextInputField';
import showOrganizationUnits from './showOrganizationUnits';
import translations from '../../services/translations/translations';
import customerApi from '../../api/contacts';

import '../../style/contacts/CreateContactPerson.css';

const CreateContactPerson = ({
  organizationUnitTypesList,
  fetchOrganizationUnitTypesList,
}: {
  organizationUnitTypesList: IOrganizationUnit[];
  fetchOrganizationUnitTypesList: () => void;
}): JSX.Element | null => {
  const { t } = useTranslation([
    translations.contacts,
    translations.common,
    translations.admin,
    translations.products,
  ]);
  const history = useHistory();
  const { contactId, personId } = useParams();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [organizationUnitTypeOptions, setOrganizationUnitTypeOptions] =
    useState<{ label: string; value: number }[]>([]);
  const [organizationUnits, setOrganizationUnits] = useState<
    { label: string; value: number }[]
  >([]);
  const [person, setPerson] = useState({
    contact: { id: 0 },
    email: '',
    firstName: '',
    jobDescription: '',
    lastName: '',
    phone: '',
    phoneWithCountryCode: '',
    title: '',
  });
  const [modalSaved, setModalSaved] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const modalMessage = saveSuccess
    ? t(`${translations.contacts}:Contact saved`)
    : t(`${translations.contacts}:Contact failed`);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setPerson((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const goPreviousPage = () => {
    if (personId !== undefined) {
      history.push(`/contacts/${person.contact.id}`);
      return;
    }

    history.push(`/contacts/${contactId}`);
  };

  useEffect(() => {
    fetchOrganizationUnitTypesList();
    const getContact = async () => {
      if (personId !== undefined) {
        try {
          const result: any = await customerApi.getPerson(personId);
          if (result !== undefined && result.removed) {
            goPreviousPage();
          } else if (result !== undefined) {
            setPerson(result);
            setOrganizationUnits(
              showOrganizationUnits(result?.organizationUnits)
            );
            setOrganizationUnitTypeOptions(
              showOrganizationUnits(result?.contact.organizationUnits)
            );
          }
        } catch (error) {
          // define action
        }
      } else {
        try {
          const result: any = await customerApi.getContact(contactId);
          if (result?.organizationUnits === []) {
            setOrganizationUnitTypeOptions(
              showOrganizationUnits(organizationUnitTypesList)
            );
          } else {
            setOrganizationUnitTypeOptions(
              showOrganizationUnits(result?.organizationUnits)
            );
          }
        } catch (error) {
          // define action
        }
      }
    };
    getContact().catch((e) => console.log(e));
  }, [personId]);

  const addUpdateContactPerson = async () => {
    const formattedPerson = {
      email: person.email,
      firstName: person.firstName,
      jobDescription: person.jobDescription,
      lastName: person.lastName,
      phone: person.phone,
      phoneWithCountryCode: person.phoneWithCountryCode,
      title: person.title,
      organizationUnitIds: organizationUnits.map((org) => org.value),
    };
    let result;
    if (personId === undefined) {
      try {
        result = await customerApi.postPerson(contactId, formattedPerson);
        setSaveSuccess(true);
        setModalSaved(true);
        history.push(`/contacts/persons/${result.id}`);
      } catch (error) {
        setSaveSuccess(false);
        setModalSaved(true);
      }
    } else {
      try {
        result = await customerApi.putPerson(personId, formattedPerson);
        setSaveSuccess(true);
        setModalSaved(true);
      } catch (error) {
        setSaveSuccess(false);
        setModalSaved(true);
      }
    }
  };

  const deleteContactPerson = () => {
    customerApi.deletePerson(personId).then(() => {
      goPreviousPage();
    });
  };

  const handleOrganizationUnits = (e: any) => {
    setOrganizationUnits(e);
  };

  return (
    <PageContainer>
      <h2 className="header-text">{t('Contact Person Info')}</h2>
      <div className="content-background">
        <Row>
          <Col>
            <TextInputField
              label={t('First Name')}
              name="firstName"
              onChange={handleChange}
              value={person.firstName}
            />
          </Col>
          <Col>
            <TextInputField
              label={t('Last Name')}
              name="lastName"
              onChange={handleChange}
              value={person.lastName}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInputField
              label={t('Email')}
              name="email"
              onChange={handleChange}
              value={person.email}
            />
          </Col>
          <Col>
            <TextInputField
              label={t('Phone')}
              name="phone"
              onChange={handleChange}
              value={person.phone}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInputField
              label={t('Job Description')}
              name="jobDescription"
              onChange={handleChange}
              value={person.jobDescription}
            />
          </Col>
          <Col>
            <TextInputField
              label={t('Phone With Country Code')}
              name="phoneWithCountryCode"
              onChange={handleChange}
              value={person.phoneWithCountryCode}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInputField
              label={t('Title')}
              name="title"
              value={person.title}
              onChange={handleChange}
            />
          </Col>
          <Col>
            <Label className="label">
              {t(`${translations.admin}:Organization Units`)}
            </Label>
            <MultiSelectDropdown
              placeholder={t(`${translations.products}:Select`)}
              required
              name="organizationUnits"
              value={organizationUnits}
              onChange={handleOrganizationUnits}
              options={organizationUnitTypeOptions}
            />
          </Col>
        </Row>
        <span>
          <Button
            className="add-button-margin contact-button"
            onClick={goPreviousPage}
          >
            {t('common:Back')}
          </Button>
          <Button
            className="add-button-margin contact-button"
            onClick={addUpdateContactPerson}
          >
            {t('common:Save')}
          </Button>
          {personId !== undefined ? (
            <>
              <Button
                className="add-button-margin contact-button"
                onClick={() => setDeleteConfirm(true)}
              >
                {t('Delete Person')}
              </Button>
              <ModalConfirm
                showing={deleteConfirm}
                setShowing={setDeleteConfirm}
                header={t('common:Confirm Delete')}
                text={t('common:Confirm Delete Text', {
                  target: `${person.firstName} ${person.lastName}`,
                })}
                yesButton={t('common:Confirm')}
                noButton={t('common:Cancel')}
                confirm={deleteContactPerson}
              />
              <ModalAccept
                showing={modalSaved}
                setShowing={setModalSaved}
                header=""
                text={modalMessage}
              />
            </>
          ) : null}
        </span>
      </div>
    </PageContainer>
  );
};

const mapStateToProps = (state: any) => ({
  organizationUnitTypesList: state.users.organizationUnits,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchOrganizationUnitTypesList: () => dispatch(fetchOrganizationUnits()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateContactPerson);
