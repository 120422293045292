import React, { useState } from 'react';
import { Row, Col, Collapse } from 'reactstrap';
import GreyBlackText from 'components/layout/GreyBlackText';
import { useTranslation } from 'react-i18next';
import translations from 'services/translations/translations';
import formatFinnishDate from 'services/dates/formatFinnishDate';
import IProjectGetSingle from '../../api/interfaces/projects/IProjectGetSingle';
import maximize from '../../assets/maximize.svg';
import minimize from '../../assets/minimize.svg';

const BasicInfoTab = ({
  project,
}: {
  project: IProjectGetSingle | undefined;
}): JSX.Element | null => {
  const { t } = useTranslation([translations.offers, translations.projects]);
  const [basicInfo, setBasicInfo] = useState<boolean>(false);
  const [billing, setBilling] = useState<boolean>(false);
  const toggleBasicInfo = () => setBasicInfo(!basicInfo);
  const toggleBilling = () => setBilling(!billing);

  if (project === undefined) {
    return null;
  }

  const getCustomerCompanyName = () =>
    project.customerContacts.length === 0
      ? ''
      : project.customerContacts[0].contact.companyName;

  const getCustomerCompanyContact = () =>
    project.customerContacts.length === 0
      ? ''
      : `${project.customerContacts[0].contactPerson.firstName} ${project.customerContacts[0].contactPerson.lastName}`;

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div onClick={toggleBasicInfo} className="header-text title-spacing">
        {basicInfo ? (
          <img src={minimize} alt="basic info off" className="collapse-icon" />
        ) : (
          <img src={maximize} alt="basic info on" className="collapse-icon" />
        )}
        {t(`${translations.projects}:General Information`).toUpperCase()}
      </div>
      <Collapse isOpen={basicInfo}>
        <Row>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Customer Office`)}
              blackText={getCustomerCompanyName()}
            />
          </Col>
          <Col md={9}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Customer`)}
              blackText={getCustomerCompanyContact()}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Project`)}
              blackText={project.name !== null ? project.name : '-'}
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Contract Type`)}
              blackText={
                project.contractType === null
                  ? '-'
                  : t(`${translations.offers}:${project.contractType}`)
              }
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Job Type`)}
              blackText={
                project.workType === null
                  ? '-'
                  : t(`${translations.offers}:${project.workType}`)
              }
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Building Type`)}
              blackText={
                project.buildingType === null
                  ? '-'
                  : t(
                      `${translations.offers}:buildingTypes:${project.buildingType}`
                    )
              }
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Address`)}
              blackText={
                project.address === null || project.address.streetAddress === ''
                  ? '-'
                  : project.address.streetAddress
              }
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:ZIP Code`)}
              blackText={
                project.address === null || project.address.postalNumber === ''
                  ? '-'
                  : project.address.postalNumber
              }
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:City`)}
              blackText={
                project.address === null || project.address.city === ''
                  ? '-'
                  : t(`${translations.offers}:${project.address.city}`)
              }
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Country`)}
              blackText={
                project.address === null || project.address.country === ''
                  ? '-'
                  : t(`${translations.offers}:${project.address.country}`)
              }
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Work Start`)}
              blackText={formatFinnishDate(
                project.start ? project.start : '-'
              ).toString()}
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Work End`)}
              blackText={formatFinnishDate(
                project.end ? project.end : '-'
              ).toString()}
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Design Office`)}
              blackText={
                project.designOfficeContacts !== null &&
                project.designOfficeContacts.length > 0
                  ? project.designOfficeContacts[0].contact.companyName
                  : '-'
              }
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Designer`)}
              blackText={
                project.designOfficeContacts !== null &&
                project.designOfficeContacts.length > 0
                  ? `${project.designOfficeContacts[0].contactPerson.firstName} ${project.designOfficeContacts[0].contactPerson.lastName}`
                  : '-'
              }
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Order Number`)}
              blackText="-" // Not yet implemented
            />
          </Col>
          <Col md={9}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:customerMark`)}
              blackText={
                project.customerMark === null || project.customerMark === ''
                  ? '-'
                  : project.customerMark
              }
            />
          </Col>
          <Col md={4}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Collateral`)}
              blackText={
                project.collateralType === null
                  ? '-'
                  : t(`${translations.offers}:${project.collateralType}`)
              }
            />
          </Col>
          <Col md={4}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:collateralAmount`)}
              blackText={
                project.collateralAmount === null
                  ? '-'
                  : String(project.collateralAmount)
              }
            />
          </Col>
          <Col md={4}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:collateralPercentage`)}
              blackText={
                project.collateralPercentage === null
                  ? '-'
                  : String(project.collateralPercentage)
              }
            />
          </Col>
          <Col md={4}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:employeeInformationType`)}
              blackText={
                project.employeeInformationType === null
                  ? '-'
                  : t(
                      `${translations.offers}:${project.employeeInformationType}`
                    )
              }
            />
          </Col>
          <Col md={4}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:wayOfReceivingType`)}
              blackText={
                project.wayOfReceivingType === null
                  ? '-'
                  : t(`${translations.offers}:${project.wayOfReceivingType}`)
              }
            />
          </Col>
          <Col md={4}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:worksiteKey`)}
              blackText={
                project.worksiteKey === null || project.worksiteKey === ''
                  ? '-'
                  : project.worksiteKey
              }
            />
          </Col>
        </Row>
      </Collapse>
      <hr />
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div onClick={toggleBilling} className="header-text title-spacing">
        {billing ? (
          <img src={minimize} alt="billing off" className="collapse-icon" />
        ) : (
          <img src={maximize} alt="billing on" className="collapse-icon" />
        )}
        {t(`${translations.offers}:Invoicing`).toUpperCase()}
      </div>
      <Collapse isOpen={billing}>
        <Row>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(
                `${translations.offers}:electronicInvoicingAddress`
              )}
              blackText={
                project.invoicingInformation.electronicInvoicingAddress ===
                  null ||
                project.invoicingInformation.electronicInvoicingAddress === ''
                  ? '-'
                  : t(
                      `${translations.offers}:${project.invoicingInformation.electronicInvoicingAddress}`
                    )
              }
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(
                `${translations.offers}:electronicInvoicingOperator`
              )}
              blackText={
                project.invoicingInformation.electronicInvoicingOperator ===
                  null ||
                project.invoicingInformation.electronicInvoicingOperator === ''
                  ? '-'
                  : project.invoicingInformation.electronicInvoicingOperator
              }
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(
                `${translations.offers}:electronicInvoicingOperatorId`
              )}
              blackText={
                project.invoicingInformation.electronicInvoicingOperatorId ===
                  null ||
                project.invoicingInformation.electronicInvoicingOperatorId ===
                  ''
                  ? '-'
                  : project.invoicingInformation.electronicInvoicingOperatorId
              }
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:invoiceReference`)}
              blackText={
                project.invoicingInformation.invoiceReference === null ||
                project.invoicingInformation.invoiceReference === ''
                  ? '-'
                  : project.invoicingInformation.invoiceReference
              }
            />
          </Col>
          <Col md={3}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Tax Rate`)}
              blackText={
                project.invoicingInformation.taxRate === null
                  ? ''
                  : String(project.invoicingInformation.taxRate)
              }
            />
          </Col>
          <Col md={9}>
            <GreyBlackText
              greyHeader={t(`${translations.offers}:Payment Term`)}
              blackText={
                project.invoicingInformation.paymentTerm === null
                  ? ''
                  : `${t(
                      `${translations.offers}:${project.invoicingInformation.paymentTerm}`
                    )} pv`
              }
            />
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default BasicInfoTab;
