import getExpenseTotal from 'services/products/calculations/getExpenseTotal';
import getInstallationsTotalInstallationPrice from 'services/products/calculations/getInstallationsTotalInstallationPrice';
import getInstallationsUnitInstallationPrice from 'services/products/calculations/getInstallationsUnitInstallationPrice';
import IProductInstallationCategory from 'api/interfaces/products/IProductInstallationCategory';
import numericStringToFloat from 'services/numbers/numericStringToFloat';
import { INSTALLATION } from 'components/offers/services/installationTypes';

import getPriceSummary from '../getPriceSummary';
import IVersion from '../IVersion';

const getVersionInstallationContract = (
  version: IVersion,
  quantity: number,
  installationCategories: IProductInstallationCategory[]
): {
  contributionMarginEurForH: number;
  installationsExpenseTotal: number;
  installationsInstallationPriceSum: number;
  installationsUnitInstallationPrice: number;
  totalHours: number;
  unitForH: number;
} => {
  const priceSummary = getPriceSummary(
    version.installations,
    version.subproducts,
    quantity
  );

  const installationsWithoutOtherExpenses = version.installations.filter(
    (installation) =>
      installationCategories.find(
        (installationCategory: IProductInstallationCategory) =>
          installationCategory.type === INSTALLATION &&
          installationCategory.category === installation.category
      )
  );

  const installationsExpenseTotal = getExpenseTotal(
    installationsWithoutOtherExpenses,
    [],
    quantity
  );

  const installationsInstallationPriceSum =
    getInstallationsTotalInstallationPrice(
      installationsWithoutOtherExpenses,
      quantity
    );

  const installationsUnitInstallationPrice =
    getInstallationsUnitInstallationPrice(installationsWithoutOtherExpenses);

  const numericAHE = numericStringToFloat(version.averageHourlyEarnings);

  const totalHours =
    numericAHE === 0 ? 0 : installationsInstallationPriceSum / numericAHE;

  const unitForH = totalHours === 0 ? 0 : quantity / totalHours;

  const contributionMarginEurForH =
    totalHours === 0 ? 0 : priceSummary.contributionMargin / totalHours;

  return {
    contributionMarginEurForH,
    installationsExpenseTotal,
    installationsInstallationPriceSum,
    installationsUnitInstallationPrice,
    totalHours,
    unitForH,
  };
};

export default getVersionInstallationContract;
