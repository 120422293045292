import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import {
  FETCH_PERMISSION_TYPES,
  FETCH_ROLES,
  FETCH_USER,
  FETCH_USERS,
  FETCH_ORGANIZATION_UNITS,
  SET_ACCESS_TOKEN,
  SET_MSAL_ACCOUNT,
  SET_MSAL_INSTANCE,
} from './types';

export function fetchPermissionTypes() {
  return {
    type: FETCH_PERMISSION_TYPES.PENDING,
  };
}

export function fetchRoles() {
  return {
    type: FETCH_ROLES.PENDING,
  };
}

export function fetchUser() {
  return {
    type: FETCH_USER.PENDING,
  };
}

export function fetchUsers() {
  return {
    type: FETCH_USERS.PENDING,
  };
}

export function fetchOrganizationUnits() {
  return {
    type: FETCH_ORGANIZATION_UNITS.PENDING,
  };
}

export function setAccessToken(accessToken: null | string): {
  type: string;
  accessToken: null | string;
} {
  return {
    type: SET_ACCESS_TOKEN,
    accessToken,
  };
}

export function setMsalInstance(
  msalInstance: null | IPublicClientApplication
): {
  type: string;
  msalInstance: null | IPublicClientApplication;
} {
  return {
    type: SET_MSAL_INSTANCE,
    msalInstance,
  };
}

export function setMsalAccount(msalAccount: null | AccountInfo): {
  type: string;
  msalAccount: null | AccountInfo;
} {
  return {
    type: SET_MSAL_ACCOUNT,
    msalAccount,
  };
}
