import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import apiProducts from 'api/products';
import ButtonLink from 'components/layout/buttons/ButtonLink';
import IProductConfigurationProduct from 'interfaces/products/IProductConfigurationProduct';
import IProductConfigurationSummary from 'interfaces/products/IProductConfigurationSummary';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import translations from 'services/translations/translations';
import { addMessage } from 'store/messages/actions';
import { finishLoading, startLoading } from 'store/loading/actions';

import ProductConfigurationProduct from './src/ProductConfigurationProduct';

import 'style/products/configurations/AddLinkToProduct.css';

const AddLinkToProduct = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation([translations.common, translations.products]);

  const configurationId = parseInt(id, 10);

  const [configurationProduct, setConfigurationProduct] =
    useState<IProductConfigurationProduct>({
      id: null,
      name: '',
      key: '',
    });

  const [name, setName] = useState('');

  const initializeConfiguration = async () => {
    dispatch(startLoading('product-configuration-summary'));
    const configuration: IProductConfigurationSummary =
      await apiProducts.configurations.summary.get(configurationId);
    dispatch(finishLoading('product-configuration-summary'));

    setName(configuration.name);
  };

  useEffect(() => {
    initializeConfiguration();
  }, [id]);

  const save = async () => {
    if (configurationProduct.id === null) {
      dispatch(addMessage(t(`${translations.common}:Please fill all fields`)));
      return;
    }

    try {
      await apiProducts.configurations.links.post(configurationId, {
        productId: configurationProduct.id,
      });

      history.push(`/products/configurations/${configurationId}`);
    } catch (e) {
      dispatch(addMessage(t(`${translations.common}:Saving failed`)));
    }
  };

  return (
    <div className="add-link-to-product">
      <h1>
        {t(`${translations.productConfigurations}:Product configuration`)}
        {' / '}
        {name}
      </h1>
      <div className="content-box">
        <h3>{t(`${translations.productConfigurations}:Add product link`)}</h3>
        <ProductConfigurationProduct
          configurationProduct={configurationProduct}
          setConfigurationProduct={setConfigurationProduct}
          showLabel
        />
        <div className="buttons">
          <ButtonLink to={`/products/configurations/${id}`}>
            {t(`${translations.common}:Back`)}
          </ButtonLink>
          <PrimaryButton onClick={save}>
            {t(`${translations.common}:Save`)}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default AddLinkToProduct;
