import React from 'react';

import IProductConfigurationsGet from 'api/interfaces/products/IProductConfigurationsGet';
import { OptionsType, OptionTypeBase } from 'react-select';

const getConfigurationOptions = (
  configurations: IProductConfigurationsGet[]
): OptionsType<OptionTypeBase> => {
  const getOption = (configuration: null | IProductConfigurationsGet) => {
    if (configuration === null) {
      return { value: null, label: '-' };
    }
    return { value: configuration.id.toString(), label: configuration.name };
  };

  return [null, ...configurations].map(
    (configuration: null | IProductConfigurationsGet) =>
      getOption(configuration)
  );
};

export default getConfigurationOptions;
