import {
  FETCH_CONTRACT_TYPES,
  FETCH_BUILDING_TYPES,
  FETCH_WORK_TYPES,
  FETCH_COLLATERAL_TYPES,
  FETCH_EMPLOYEE_INFORMATION_TYPES,
  FETCH_WAY_OF_RECEIVING_TYPES,
  FETCH_TAX_RATE_TYPES,
  FETCH_TAB_TYPES,
  FETCH_HINDRANCE_TYPES,
  FETCH_PLACE_STATUS_TYPES,
} from './types';

export function fetchContractTypes() {
  return {
    type: FETCH_CONTRACT_TYPES.PENDING,
  };
}

export function fetchBuildingTypes() {
  return {
    type: FETCH_BUILDING_TYPES.PENDING,
  };
}

export function fetchWorkTypes() {
  return {
    type: FETCH_WORK_TYPES.PENDING,
  };
}

export function fetchCollateralTypes() {
  return {
    type: FETCH_COLLATERAL_TYPES.PENDING,
  };
}

export function fetchEmployeeInformationTypes() {
  return {
    type: FETCH_EMPLOYEE_INFORMATION_TYPES.PENDING,
  };
}

export function fetchWayOfReceivingTypes() {
  return {
    type: FETCH_WAY_OF_RECEIVING_TYPES.PENDING,
  };
}

export function fetchTaxRateTypes() {
  return {
    type: FETCH_TAX_RATE_TYPES.PENDING,
  };
}

export function fetchTabTypes() {
  return {
    type: FETCH_TAB_TYPES.PENDING,
  };
}

export function fetchHindranceTypes() {
  return {
    type: FETCH_HINDRANCE_TYPES.PENDING,
  };
}

export function fetchPlaceStatusTypes() {
  return {
    type: FETCH_PLACE_STATUS_TYPES.PENDING,
  };
}
