import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form } from 'reactstrap';

import translations from 'services/translations/translations';
import {
  fetchFileTypes,
  fetchProductIdTypes,
  fetchPriceTypes,
  fetchMeasurementTypes,
  fetchUnits,
  fetchCategories,
} from 'store/products/actions';
import {
  PALLET,
  PACKAGE,
} from 'services/enums/products/productMeasurementTypes';
import BasicFormContainer from 'components/layout/BasicFormContainer';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import IFile from 'interfaces/forms/IFile';
import IMeasurement from 'interfaces/products/IMeasurement';
import IPrice from 'interfaces/products/IPrice';
import IProductPost from 'api/interfaces/products/IProductPost';
import IProductGetSingle from 'api/interfaces/products/IProductGetSingle';
import { useHistory, useParams } from 'react-router-dom';
import getTargetValue from 'services/forms/getTargetValue';
import IProductId from 'interfaces/products/IProductId';
import IContact from 'interfaces/contacts/IContact';
import IMeasurementGroupType from 'interfaces/products/IMeasurementGroupType';
import ModalAccept from 'components/layout/modals/ModalAccept';
import ModalConfirm from 'components/layout/modals/ModalConfirm';
import PageContainer from 'components/layout/PageContainer';
import IAttribute from 'interfaces/products/IAttribute';

import getIdValues from './getIdValues';
import getEmptyMeasurement from './getEmptyMeasurement';
import getEmptyPrice from './getEmptyPrice';
import getProductPost from './getProductPost';
import ProductMeasurementRows from './ProductMeasurementRows';
import ProductBasicInfo from './ProductBasicInfo';
import ProductAttribute from './ProductAttribute';
import updateMeasurement from './updateMeasurement';
import updatePrice from './updatePrice';
import ProductFiles from './src/product/ProductFiles';
import apiProducts from '../../api/products';
import apiContacts from '../../api/contacts';

import {
  getPriceRows,
  showCategoryOptions,
  showTagOptions,
} from './ProductSelectOptions';

import 'style/products/Product.css';

const Product = ({
  idTypesList,
  priceTypesList,
  measurementTypesList,
  categoriesList,
  unitsList,
  fetchTypesList,
  fetchFileTypesList,
  fetchPriceTypesList,
  fetchMeasurementTypesList,
  fetchCategoriesList,
  fetchUnitsList,
}: {
  priceTypesList: string[];
  idTypesList: string[];
  measurementTypesList: IMeasurementGroupType[];
  categoriesList: string[];
  unitsList: string[];
  fetchTypesList: () => void;
  fetchFileTypesList: () => void;
  fetchPriceTypesList: () => void;
  fetchMeasurementTypesList: () => void;
  fetchCategoriesList: () => void;
  fetchUnitsList: () => void;
}): JSX.Element | null => {
  const history = useHistory();
  const { id } = useParams();
  const [productId, setProductId] = useState<number>(id);
  const { t } = useTranslation([translations.products, translations.common]);
  const [categories, setCategories] = useState<
    { label: string; value: string }[]
  >([]);
  const [formState, setFormState] = useState({
    name: '',
    displayName: '',
    sPName: '',
  });
  const [idValues, setIdValues] = useState<IProductId[]>([]);
  const [priceFormValues, setPriceFormValues] = useState<IPrice[]>([]);
  const [retrievedMeasurements, setRetrievedMeasurements] = useState<
    IMeasurement[]
  >([]);
  const [retrievedIds, setRetrievedIds] = useState<IProductId[]>([]);
  const [productMeasurFormValues, setProductMeasurFormValues] = useState<
    IMeasurement[]
  >([]);
  const [palletMeasurFormValues, setPalletMeasurFormValues] = useState<
    IMeasurement[]
  >([]);
  const [packageMeasurFormValues, setPackageMeasurFormValues] = useState<
    IMeasurement[]
  >([]);
  const [attributeFormValues, setAttributeFormValues] = useState<IAttribute[]>(
    []
  );
  const [tags, setTags] = useState<{ label: string; value: string }[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const [suppliers, setSuppliers] = useState<IContact[]>();
  const [supplierId, setSupplierId] = useState<null | number>(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalDeleteConfirmation, setModalDeleteConfirmation] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const toggleDeleteConfirmation = () =>
    setModalDeleteConfirmation(!modalDeleteConfirmation);
  const [deliveryCategory, setDeliveryCategory] = useState<string>('');
  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const goPreviousPage = () => {
    history.push('/products');
  };

  useEffect(() => {
    // If not undefined there is a product to modify
    if (id !== undefined) {
      const submit = async () => {
        try {
          const result: IProductGetSingle = await apiProducts.getProduct(id);
          if (result !== undefined && result.removed) {
            goPreviousPage();
          } else if (result !== undefined) {
            setFormState({
              ...formState,
              name: result?.name,
              displayName: result?.displayName,
              sPName: result?.supplierProductName,
            });
            setSupplierId(result.supplier === null ? null : result.supplier.id);
            setRetrievedMeasurements(result.measurements);
            setPriceFormValues(result?.prices);
            setAttributeFormValues(result?.attributes);
            setRetrievedIds(result.productIds);
            setDeliveryCategory(result?.deliveryCategory);
            const formattedCategories = showCategoryOptions(
              result?.categories,
              translations,
              t
            );
            const formattedTags = showTagOptions(result?.tags, translations, t);
            setCategories(formattedCategories);
            setTags(formattedTags);
            setFiles(
              result.files.map((file: IFile, index: number) => ({
                ...file,
                key: index,
              }))
            );
          }
        } catch (error) {
          console.log(error);
          history.push('/products');
        }
      };
      submit();
    }
  }, [id]);

  useEffect(() => {
    if (
      measurementTypesList.length !== 0 &&
      retrievedMeasurements.length !== 0
    ) {
      const productTypes = measurementTypesList.filter((x) => x.group === null);
      const palletTypes = measurementTypesList.filter(
        (x) => x.group === PALLET
      );
      const packageTypes = measurementTypesList.filter(
        (x) => x.group === PACKAGE
      );

      const prodTemp: IMeasurement[] = [];
      retrievedMeasurements.forEach((object) => {
        productTypes.forEach((prod) => {
          if (object.type === prod.type) {
            prodTemp.push(object);
          }
        });
      });
      setProductMeasurFormValues(prodTemp);
      const palletTemp: IMeasurement[] = [];
      retrievedMeasurements.forEach((object) => {
        palletTypes.forEach((prod) => {
          if (object.type === prod.type) {
            palletTemp.push(object);
          }
        });
      });
      setPalletMeasurFormValues(palletTemp);

      const packageTemp: IMeasurement[] = [];
      retrievedMeasurements.forEach((object) => {
        packageTypes.forEach((prod) => {
          if (object.type === prod.type) {
            packageTemp.push(object);
          }
        });
      });
      setPackageMeasurFormValues(packageTemp);
    }
  }, [measurementTypesList, retrievedMeasurements]);

  useEffect(() => {
    if (id !== undefined) {
      let tempIdValues: IProductId[] = [];
      tempIdValues = getIdValues(idTypesList, retrievedIds);
      setIdValues(tempIdValues);
    } else {
      const tempIdValues = [];
      if (idTypesList !== undefined) {
        for (let index = 0; index < idTypesList.length; index++) {
          const idValue: IProductId = {
            type: idTypesList[index],
            value: '',
          };
          tempIdValues.push(idValue);
        }
      }
      setIdValues(tempIdValues);
    }
  }, [id, idTypesList, retrievedIds]);

  useEffect(() => {
    fetchTypesList();
    fetchFileTypesList();
    fetchPriceTypesList();
    fetchMeasurementTypesList();
    fetchUnitsList();
    fetchCategoriesList();
    const getSupplierIds = async () => {
      try {
        const result: IContact[] = await apiContacts.get({
          limit: 100,
          offset: 0,
          type: 'supplier',
        });

        if (result !== undefined) {
          setSuppliers(result);
        }
      } catch (error) {
        // define action
      }
    };
    getSupplierIds().catch((e) => console.log(e));
  }, [
    fetchFileTypesList,
    fetchPriceTypesList,
    fetchTypesList,
    fetchMeasurementTypesList,
    fetchUnitsList,
    fetchCategoriesList,
  ]);

  const addIdValue = (e: any, index: any) => {
    const newValues = [...idValues];
    newValues[index].value = getTargetValue(e);
    setIdValues(newValues);
  };

  const handleFormState = (e: any) => {
    setFormState({
      ...formState,
      [e.target.name]: getTargetValue(e),
    });
  };

  const handleCategories = (e: any) => {
    setCategories(e);
  };

  const handleTags = (e: any) => {
    setTags(e);
  };

  const removeFile = (file: IFile) => {
    if (file.id === null) {
      return;
    }

    setFiles(
      files.filter((existingFile: IFile) => existingFile.id !== file.id)
    );
  };

  const updatePriceRow = (e: any, index: any) => {
    let newValues = [];
    newValues = updatePrice(priceFormValues, e, index);
    setPriceFormValues(newValues);
  };

  const updateProductMeasurementRow = (e: any, index: any) => {
    let newValues = [];
    newValues = updateMeasurement(productMeasurFormValues, e, index);
    setProductMeasurFormValues(newValues);
  };

  const updatePalletMeasurementRow = (e: any, index: any) => {
    let newValues = [];
    newValues = updateMeasurement(palletMeasurFormValues, e, index);
    setPalletMeasurFormValues(newValues);
  };

  const updatePackageMeasurementRow = (e: any, index: any) => {
    let newValues = [];
    newValues = updateMeasurement(packageMeasurFormValues, e, index);
    setPackageMeasurFormValues(newValues);
  };

  const getSelectedMeasurements = (groupType: string | null): string[] => {
    if (groupType === PALLET) {
      return palletMeasurFormValues.map((formValue) => formValue.type);
    }

    if (groupType === PACKAGE) {
      return packageMeasurFormValues.map((formValue) => formValue.type);
    }

    return productMeasurFormValues.map((formValue) => formValue.type);
  };

  const getAvailableMeasurementOptions = (groupType: string | null) =>
    measurementTypesList.filter(
      (measurementType) =>
        measurementType.group === groupType &&
        !getSelectedMeasurements(groupType).includes(measurementType.type)
    );

  useEffect(() => {
    fetchMeasurementTypesList();
  }, [fetchMeasurementTypesList]);

  const getSelectedPrices = (): string[] =>
    priceFormValues.map((formValue) => formValue.type);

  const getAvailablePriceOptions: string[] = priceTypesList.filter(
    (priceType) => !getSelectedPrices().includes(priceType)
  );

  useEffect(() => {
    fetchPriceTypesList();
  }, [fetchPriceTypesList]);

  const addProductMeasurementRow = () => {
    let newValues = [];
    newValues = getEmptyMeasurement(
      productMeasurFormValues,
      unitsList,
      measurementTypesList,
      null
    );
    setProductMeasurFormValues(newValues);
  };

  const addPackageMeasurementRow = () => {
    let newValues = [];
    newValues = getEmptyMeasurement(
      packageMeasurFormValues,
      unitsList,
      measurementTypesList,
      PACKAGE
    );
    setPackageMeasurFormValues(newValues);
  };

  const addPalletMeasurementRow = () => {
    let newValues = [];
    newValues = getEmptyMeasurement(
      palletMeasurFormValues,
      unitsList,
      measurementTypesList,
      PALLET
    );
    setPalletMeasurFormValues(newValues);
  };

  const addPriceRow = () => {
    let newValues = [];
    newValues = getEmptyPrice(priceFormValues, unitsList, priceTypesList);
    setPriceFormValues(newValues);
  };

  const deletePriceRow = (index: any, rows: any) => {
    const list = [...rows];
    list.splice(index, 1);
    setPriceFormValues(list);
  };

  const deleteProductMeasurRow = (index: any, rows: any) => {
    const list = [...rows];
    list.splice(index, 1);
    setProductMeasurFormValues(list);
  };

  const deletePalletMeasurRow = (index: any, rows: any) => {
    const list = [...rows];
    list.splice(index, 1);
    setPalletMeasurFormValues(list);
  };

  const deletePackageMeasurRow = (index: any, rows: any) => {
    const list = [...rows];
    list.splice(index, 1);
    setPackageMeasurFormValues(list);
  };

  const updateSupplier = (e: any) => {
    if (e.target.value === '') {
      setSupplierId(null);
      return;
    }

    const supId = suppliers?.find(
      (x) => x.id === parseInt(e.target.value, 10)
    )?.id;

    setSupplierId(supId === undefined ? null : supId);
  };

  const updateDeliveryCategory = (e: any) => {
    setDeliveryCategory(getTargetValue(e));
  };

  const submitProduct = () => {
    const validProduct =
      formState.name !== '' &&
      formState.sPName !== '' &&
      formState.displayName !== '' &&
      supplierId !== null &&
      deliveryCategory !== '';

    if (!validProduct) {
      setErrorMessage(t(`${translations.common}:Fill all fields`));
      setFormError(true);
      return;
    }

    const product: IProductPost = getProductPost(
      idValues,
      attributeFormValues,
      productMeasurFormValues
        .concat(palletMeasurFormValues)
        .concat(packageMeasurFormValues),
      priceFormValues,
      supplierId,
      categories,
      formState,
      files,
      deliveryCategory,
      tags
    );

    const submit = async () => {
      let result;
      if (productId !== undefined) {
        try {
          await apiProducts.put(productId, product);
          setErrorMessage(t(`${translations.products}:Product saved`));
          setFormError(true);
        } catch (error) {
          setErrorMessage(t(`${translations.products}:Product failed`));
          setFormError(true);
        }
      } else {
        try {
          result = await apiProducts.post(product);
          setErrorMessage(t(`${translations.products}:Product saved`));
          setFormError(true);
          setProductId(result.id);
        } catch (error) {
          setErrorMessage(t(`${translations.products}:Product failed`));
          setFormError(true);
        }
      }
    };

    submit().catch((e) => console.log(e));
  };

  const deleteProduct = async () => {
    try {
      const result = await apiProducts.delete(productId);
      if (result !== undefined && result.success === true) {
        toggleDelete();
        toggleDeleteConfirmation();
        setDeleteSuccess(true);
      } else {
        setDeleteSuccess(false);
      }
    } catch (error) {
      setDeleteSuccess(false);
    }
  };

  return (
    <PageContainer>
      <BasicFormContainer>
        <Form>
          <ProductBasicInfo
            idValues={idValues}
            addIdValue={addIdValue}
            formState={formState}
            handleFormState={handleFormState}
            updateSupplier={updateSupplier}
            suppliers={suppliers || []}
            categories={categories}
            handleCategories={handleCategories}
            categoriesList={categoriesList}
            supplierId={supplierId}
            deliveryCategory={deliveryCategory}
            updateDeliveryCategory={updateDeliveryCategory}
            tags={tags}
            handleTags={handleTags}
          />
          <hr />
          <ProductAttribute
            attributeFormValues={attributeFormValues}
            setAttributeFormValues={setAttributeFormValues}
          />
          <hr />
          <ProductMeasurementRows
            measurementTypesList={measurementTypesList}
            unitsList={unitsList}
            measurFormValues={productMeasurFormValues}
            updateMeasurementRow={updateProductMeasurementRow}
            deleteRow={deleteProductMeasurRow}
            nameKey="prodMeasur"
            group={null}
          />
          {getAvailableMeasurementOptions(null).length === 0 ? null : (
            <Row>
              <Col>
                <PrimaryButton onClick={addProductMeasurementRow}>
                  {t(`${translations.products}:Add product measurement`)}
                </PrimaryButton>
              </Col>
            </Row>
          )}
          <hr />
          <ProductMeasurementRows
            measurementTypesList={measurementTypesList}
            unitsList={unitsList}
            measurFormValues={palletMeasurFormValues}
            updateMeasurementRow={updatePalletMeasurementRow}
            deleteRow={deletePalletMeasurRow}
            nameKey="palletMeasur"
            group="pallet"
          />
          {getAvailableMeasurementOptions(PALLET).length === 0 ? null : (
            <Row>
              <Col>
                <PrimaryButton onClick={addPalletMeasurementRow}>
                  {t(`${translations.products}:Add pallet measurement`)}
                </PrimaryButton>
              </Col>
            </Row>
          )}
          <hr />
          <ProductMeasurementRows
            measurementTypesList={measurementTypesList}
            unitsList={unitsList}
            measurFormValues={packageMeasurFormValues}
            updateMeasurementRow={updatePackageMeasurementRow}
            deleteRow={deletePackageMeasurRow}
            nameKey="packageMeasur"
            group="package"
          />
          {getAvailableMeasurementOptions(PACKAGE).length === 0 ? null : (
            <Row>
              <Col>
                <PrimaryButton onClick={addPackageMeasurementRow}>
                  {t(`${translations.products}:Add package measurement`)}
                </PrimaryButton>
              </Col>
            </Row>
          )}
          <hr />
          {getPriceRows(
            priceTypesList,
            unitsList,
            priceFormValues,
            updatePriceRow,
            deletePriceRow,
            t,
            translations
          )}
          {getAvailablePriceOptions.length === 0 ? null : (
            <Row>
              <Col>
                <PrimaryButton onClick={addPriceRow}>
                  {t(`${translations.products}:Add price`)}
                </PrimaryButton>
              </Col>
            </Row>
          )}
          <hr />
          <Row>
            <Col>
              <ProductFiles
                files={files}
                id="product-attachments"
                removeFile={removeFile}
                setFiles={setFiles}
              />
            </Col>
          </Row>
          <hr />
          <ModalAccept
            showing={formError}
            setShowing={setFormError}
            header=""
            text={errorMessage}
          />
          <ModalConfirm
            showing={modalDelete}
            setShowing={toggleDelete}
            header={t('common:Confirm Delete')}
            text={t(`${translations.products}:Delete product confirmation`)}
            yesButton={t('common:Confirm')}
            noButton={t('common:Cancel')}
            confirm={deleteProduct}
          />
          <ModalAccept
            showing={modalDeleteConfirmation}
            setShowing={toggleDeleteConfirmation}
            header=""
            text={
              deleteSuccess
                ? t(`${translations.common}:Delete Success`)
                : t(`${translations.common}:Delete Failure`)
            }
          />
          <Row>
            <Col>
              <PrimaryButton onClick={goPreviousPage}>
                {t(`${translations.common}:Back`)}
              </PrimaryButton>

              {productId !== undefined ? (
                <PrimaryButton
                  className="add-button-margin"
                  onClick={toggleDelete}
                >
                  {t(`${translations.common}:Remove`)}
                </PrimaryButton>
              ) : (
                ''
              )}

              <PrimaryButton
                className="add-button-margin"
                onClick={submitProduct}
              >
                {t(`${translations.products}:Submit`)}
              </PrimaryButton>
            </Col>
          </Row>
        </Form>
      </BasicFormContainer>
    </PageContainer>
  );
};

const mapStateToProps = (state: any) => ({
  idTypesList: state.products.productTypes,
  priceTypesList: state.products.priceTypes,
  measurementTypesList: state.products.measurementTypes,
  categoriesList: state.products.categories,
  unitsList: state.products.units,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchTypesList: () => dispatch(fetchProductIdTypes()),
  fetchFileTypesList: () => dispatch(fetchFileTypes()),
  fetchPriceTypesList: () => dispatch(fetchPriceTypes()),
  fetchMeasurementTypesList: () => dispatch(fetchMeasurementTypes()),
  fetchCategoriesList: () => dispatch(fetchCategories()),
  fetchUnitsList: () => dispatch(fetchUnits()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
