import React, { useEffect } from 'react';
import { Row, Col, Collapse, Form, FormGroup, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ContentSizedTextarea from 'components/layout/forms/ContentSizedTextarea';
import DatePicker from 'components/layout/forms/DatePicker';
import maximize from 'assets/maximize.svg';
import minimize from 'assets/minimize.svg';
import NumberInputField from 'components/layout/forms/NumberInputField';
import PrimaryButton from 'components/layout/buttons/PrimaryButton';
import SearchDropdown from 'components/layout/forms/SearchDropdown';
import SelectInputFieldControlled from 'components/layout/forms/SelectInputFieldControlled';
import TextInputField from 'components/layout/forms/TextInputField';
import translations from 'services/translations/translations';
import UpdatedField from 'components/layout/forms/UpdatedField';
import UserSelect from 'components/layout/forms/UserSelect';
import { APPROVED } from 'services/enums/offers/Statuses';
import { NO } from 'services/enums/offers/collateralTypes';

import {
  fetchBuildingTypes,
  fetchCollateralTypes,
  fetchContractTypes,
  fetchEmployeeInformationTypes,
  fetchWayOfReceivingTypes,
  fetchWorkTypes,
} from 'store/projects/actions';

import {
  showDesigners,
  showOptionsFirstBlank,
} from 'components/offers/OfferSelectOptions';

import IOffer from './IOffer';
import OfferInvoicingInfo from './OfferInvoicingInfo';
import Status from './fields/Status';

import 'style/layout/Icons/CollapseIcon.css';
import 'style/offers/OfferBasicInfo.css';

const OfferBasicInfo = ({
  toggleOffer,
  offerIsOpen,
  formState,
  handleFormState,
  customerOffice,
  handleCustomerOffice,
  searchCustomerOffice,
  startDate,
  onChangeDate,
  endDate,
  designOffice,
  handleDesignOffice,
  searchDesignOffice,
  designers,
  saveOffer,
  customers,
  paymentTerms,
  handlePaymentTerm,
  paymentTerm,
  initialStatus,
  signatureUser,
  handleSignatureUser,
}: {
  toggleOffer: any;
  offerIsOpen: boolean;
  formState: IOffer;
  handleFormState: any;
  customerOffice: any;
  handleCustomerOffice: any;
  searchCustomerOffice: any;
  startDate: any;
  onChangeDate: any;
  endDate: any;
  designOffice: any;
  handleDesignOffice: any;
  searchDesignOffice: any;
  designers: any;
  saveOffer: any;
  customers: any;
  paymentTerms: any;
  handlePaymentTerm: any;
  paymentTerm: any;
  initialStatus: null | string;
  signatureUser: any;
  handleSignatureUser: any;
}): JSX.Element | null => {
  const dispatch = useDispatch();
  const { t } = useTranslation([
    translations.offers,
    translations.common,
    translations.products,
    translations.userManagement,
  ]);

  useEffect(() => {
    dispatch(fetchBuildingTypes());
    dispatch(fetchCollateralTypes());
    dispatch(fetchContractTypes());
    dispatch(fetchEmployeeInformationTypes());
    dispatch(fetchWayOfReceivingTypes());
    dispatch(fetchWorkTypes());
  }, []);

  const buildingTypes = useSelector(
    (state: any) => state.projects.buildingTypes
  );

  const collateralTypes = useSelector(
    (state: any) => state.projects.collateralTypes
  );

  const contractTypes = useSelector(
    (state: any) => state.projects.contractTypes
  );

  const employeeInformationTypes = useSelector(
    (state: any) => state.projects.employeeInformationTypes
  );

  const wayOfReceivingTypes = useSelector(
    (state: any) => state.projects.wayOfReceivingTypes
  );

  const workTypes = useSelector((state: any) => state.projects.workTypes);

  const offerIsApproved = () => initialStatus === APPROVED;

  const noCollateral = () => formState.collateral === NO;

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div onClick={toggleOffer} className="header-text title-spacing">
        {offerIsOpen ? (
          <img src={minimize} alt="toggle-offer" className="collapse-icon" />
        ) : (
          <img src={maximize} alt="toggle-offer" className="collapse-icon" />
        )}
        {t(`${translations.offers}:Basic Info`).toUpperCase()}
      </div>
      <Collapse isOpen={offerIsOpen}>
        <Form>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label className="label">
                  {t(`${translations.offers}:Customer Office`)}
                </Label>
                <SearchDropdown
                  value={customerOffice}
                  required
                  placeholder={t(`${translations.common}:Search`)}
                  className="text-field search-field"
                  name="customerOffice"
                  onChange={handleCustomerOffice}
                  loadOptions={searchCustomerOffice}
                  disabled={offerIsApproved()}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <SelectInputFieldControlled
                value={formState.customer}
                label={t(`${translations.offers}:Customer`)}
                required
                id="customer"
                name="customer"
                onChange={handleFormState}
                options={customers ? showDesigners(customers) : ''}
                disabled={offerIsApproved()}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInputField
                value={formState.target}
                label={t(`${translations.offers}:Target`)}
                required
                id="target"
                name="target"
                onChange={handleFormState}
                disabled={offerIsApproved()}
              />
            </Col>
            <Col md={3}>
              <SelectInputFieldControlled
                value={formState.contractType}
                label={t(`${translations.offers}:Contract Type`)}
                required
                id="contractType"
                name="contractType"
                onChange={handleFormState}
                options={
                  contractTypes
                    ? showOptionsFirstBlank(
                        'contractTypes',
                        contractTypes,
                        translations.offers,
                        ':',
                        t
                      )
                    : ''
                }
                disabled={offerIsApproved()}
              />
            </Col>
            <Col md={3}>
              <SelectInputFieldControlled
                value={formState.jobType}
                label={t(`${translations.offers}:Job Type`)}
                required
                id="jobType"
                name="jobType"
                onChange={handleFormState}
                options={
                  workTypes
                    ? showOptionsFirstBlank(
                        'workTypes',
                        workTypes,
                        translations.offers,
                        ':',
                        t
                      )
                    : ''
                }
                disabled={offerIsApproved()}
              />
            </Col>
            <Col md={3}>
              <SelectInputFieldControlled
                value={formState.buildingType}
                label={t(`${translations.offers}:Building Type`)}
                required
                id="buildingType"
                name="buildingType"
                onChange={handleFormState}
                options={
                  buildingTypes
                    ? showOptionsFirstBlank(
                        'buildingTypes',
                        buildingTypes,
                        `${translations.offers}:buildingTypes`,
                        ':',
                        t
                      )
                    : ''
                }
                disabled={offerIsApproved()}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <TextInputField
                value={formState.address}
                label={t(`${translations.offers}:Address`)}
                required
                id="address"
                name="address"
                onChange={handleFormState}
                disabled={offerIsApproved()}
              />
            </Col>
            <Col md={3}>
              <TextInputField
                value={formState.zipCode}
                label={t(`${translations.offers}:ZIP Code`)}
                required
                id="zipCode"
                name="zipCode"
                onChange={handleFormState}
                disabled={offerIsApproved()}
              />
            </Col>
            <Col md={3}>
              <TextInputField
                value={formState.city}
                label={t(`${translations.offers}:City`)}
                required
                id="city"
                name="city"
                onChange={handleFormState}
                disabled={offerIsApproved()}
              />
            </Col>
            <Col md={3}>
              <TextInputField
                value={formState.country}
                label={t(`${translations.offers}:Country`)}
                required
                id="country"
                name="country"
                onChange={handleFormState}
                disabled={offerIsApproved()}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label className="label">
                  {t(`${translations.offers}:Work Start`)}
                </Label>
                <DatePicker
                  showWeekNumbers
                  selected={startDate}
                  onChange={(date) => onChangeDate(date, 'start')}
                  id="startDate"
                  required
                  locale="fi"
                  disabled={offerIsApproved()}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label className="label">
                  {t(`${translations.offers}:Work End`)}
                </Label>
                <DatePicker
                  showWeekNumbers
                  selected={endDate}
                  onChange={(date) => onChangeDate(date, 'end')}
                  id="endDate"
                  required
                  locale="fi"
                  disabled={offerIsApproved()}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label className="label">
                  {t(`${translations.offers}:Design Office`)}
                </Label>
                <SearchDropdown
                  value={designOffice}
                  required
                  placeholder={t(`${translations.common}:Search`)}
                  className="text-field search-field"
                  name="designOffice"
                  onChange={handleDesignOffice}
                  loadOptions={searchDesignOffice}
                  disabled={offerIsApproved()}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <SelectInputFieldControlled
                value={formState.designer}
                label={t(`${translations.offers}:Designer`)}
                required
                id="designer"
                name="designer"
                onChange={handleFormState}
                options={designers ? showDesigners(designers) : ''}
                disabled={offerIsApproved()}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInputField
                disabled
                value={formState.orderNumber}
                label={t(`${translations.offers}:Order Number`)}
                required
                id="orderNumber"
                name="orderNumber"
                onChange={handleFormState}
              />
            </Col>
            <Col>
              <TextInputField
                value={formState.customerMark}
                label={t(`${translations.offers}:customerMark`)}
                required
                id="customerMark"
                name="customerMark"
                onChange={handleFormState}
                disabled={offerIsApproved()}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectInputFieldControlled
                value={formState.collateral}
                label={t(`${translations.offers}:Collateral`)}
                required
                id="collateral"
                name="collateral"
                onChange={handleFormState}
                options={
                  collateralTypes
                    ? showOptionsFirstBlank(
                        'collateral',
                        collateralTypes,
                        translations.offers,
                        ':',
                        t
                      )
                    : ''
                }
                disabled={offerIsApproved()}
              />
            </Col>
            <Col>
              {noCollateral() ? null : (
                <NumberInputField
                  value={formState.collateralAmount}
                  label={t(`${translations.offers}:collateralAmount`)}
                  required
                  id="collateralAmount"
                  name="collateralAmount"
                  onChange={handleFormState}
                  disabled={offerIsApproved()}
                />
              )}
            </Col>
            <Col>
              {noCollateral() ? null : (
                <NumberInputField
                  value={formState.collateralPercentage}
                  label={t(`${translations.offers}:collateralPercentage`)}
                  required
                  id="collateralPercentage"
                  name="collateralPercentage"
                  onChange={handleFormState}
                  disabled={offerIsApproved()}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectInputFieldControlled
                value={formState.employeeInformationType}
                label={t(`${translations.offers}:employeeInformationType`)}
                required
                id="employeeInformationType"
                name="employeeInformationType"
                onChange={handleFormState}
                options={
                  employeeInformationTypes
                    ? showOptionsFirstBlank(
                        'employeeInformationType',
                        employeeInformationTypes,
                        translations.offers,
                        ':',
                        t
                      )
                    : ''
                }
                disabled={offerIsApproved()}
              />
            </Col>
            <Col>
              <SelectInputFieldControlled
                value={formState.wayOfReceivingType}
                label={t(`${translations.offers}:wayOfReceivingType`)}
                required
                id="wayOfReceivingType"
                name="wayOfReceivingType"
                onChange={handleFormState}
                options={
                  wayOfReceivingTypes
                    ? showOptionsFirstBlank(
                        'wayOfReceivingType',
                        wayOfReceivingTypes,
                        translations.offers,
                        ':',
                        t
                      )
                    : ''
                }
                disabled={offerIsApproved()}
              />
            </Col>
            <Col>
              <TextInputField
                value={formState.worksiteKey}
                label={t(`${translations.offers}:worksiteKey`)}
                required
                id="worksiteKey"
                name="worksiteKey"
                onChange={handleFormState}
                disabled={offerIsApproved()}
              />
            </Col>
          </Row>
          <div className="subsection">
            <OfferInvoicingInfo
              formState={formState}
              handleFormState={handleFormState}
              paymentTerms={paymentTerms}
              handlePaymentTerm={handlePaymentTerm}
              paymentTerm={paymentTerm}
              initialStatus={initialStatus}
            />
          </div>
          <div className="subsection">
            <Row>
              <Col>
                <h3>{t(`${translations.offers}:offer`).toUpperCase()} PDF</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInputField
                  value={formState.attachmentsNote}
                  label={t(`${translations.offers}:Attachments`)}
                  required
                  name="attachmentsNote"
                  onChange={handleFormState}
                  disabled={offerIsApproved()}
                />
              </Col>
              <Col>
                <TextInputField
                  value={formState.cargo}
                  label={t(`${translations.offers}:Cargo`)}
                  required
                  name="cargo"
                  onChange={handleFormState}
                  disabled={offerIsApproved()}
                />
              </Col>
              <Col>
                <TextInputField
                  value={formState.validity}
                  label={t(`${translations.offers}:Validity`)}
                  required
                  name="validity"
                  onChange={handleFormState}
                  disabled={offerIsApproved()}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInputField
                  value={formState.deliveryTimeNote}
                  label={t(`${translations.offers}:Delivery time`)}
                  required
                  name="deliveryTimeNote"
                  onChange={handleFormState}
                  disabled={offerIsApproved()}
                />
              </Col>
              <Col>
                <TextInputField
                  value={formState.invoiceNote}
                  label={t(`${translations.offers}:Invoicing`)}
                  required
                  name="invoiceNote"
                  onChange={handleFormState}
                  disabled={offerIsApproved()}
                />
              </Col>
              <Col>
                <UserSelect
                  disabled={offerIsApproved()}
                  label={t(`${translations.offers}:Signature`)}
                  onChange={handleSignatureUser}
                  ownOrganizationUnit
                  value={signatureUser}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ContentSizedTextarea
                  disabled={offerIsApproved()}
                  label={t(`${translations.calculationWindow}:Offer text`)}
                  name="offerText"
                  onChange={handleFormState}
                  value={formState.offerText}
                />
              </Col>
            </Row>
          </div>
          <div className="subsection">
            <Row>
              <Col>
                <h3>{t(`${translations.offers}:Status`).toUpperCase()}</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <Status
                  formState={formState}
                  handleFormState={handleFormState}
                  initialStatus={initialStatus}
                />
              </Col>
              <Col>
                <UpdatedField
                  label={t(`${translations.common}:Created`)}
                  updated={formState.created}
                  updatedUser={formState.createdUser}
                />
              </Col>
              <Col>
                <UpdatedField
                  label={t(`${translations.common}:Updated`)}
                  updated={formState.updated}
                  updatedUser={formState.updatedUser}
                />
              </Col>
              <Col>
                <TextInputField
                  disabled
                  label={t(`${translations.userManagement}:Organization unit`)}
                  required
                  value={
                    formState.organizationUnit === null
                      ? ''
                      : formState.organizationUnit.name
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} />
              <Col xs={6}>
                {offerIsApproved() ? null : (
                  <PrimaryButton
                    className="offer-save-button"
                    onClick={() => saveOffer()}
                  >
                    {t(`${translations.common}:Save`).toLocaleUpperCase()}
                  </PrimaryButton>
                )}
              </Col>
            </Row>
          </div>
        </Form>
      </Collapse>
    </>
  );
};

export default OfferBasicInfo;
