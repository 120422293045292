import IProjectGet from 'api/interfaces/projects/IProjectGet';
import IProjectGetSingle from 'api/interfaces/projects/IProjectGetSingle';
import IHindranceGet from 'api/interfaces/projects/IHindranceGet';
import IWorkGroupPost from 'interfaces/projects/IWorkGroupPost';
import api from './api';
import IBasicInfoGet from './interfaces/projects/IBasicInfoGet';
import IPlaceGet from './interfaces/projects/IPlaceGet';
import ISuccessResult from './interfaces/results/ISuccessResult';
import IPlaceStatus from './interfaces/projects/IPlaceStatus';

export default {
  buildingTypes: {
    get: async (): Promise<string[]> =>
      api.get('/api/v1/projects/types/building'),
  },
  collateralTypes: {
    get: async (): Promise<string[]> =>
      api.get('/api/v1/projects/types/collateral'),
  },
  contractTypes: {
    get: async (): Promise<string[]> =>
      api.get('/api/v1/projects/types/contract'),
  },
  count: async (filters: {
    search?: string;
    customerId?: string;
    start?: string;
    end?: string;
  }): Promise<any> => api.get('/api/v1/projects/count', filters),
  employeeInformationTypes: {
    get: async (): Promise<string[]> =>
      api.get('/api/v1/projects/types/employee-information'),
  },
  files: {
    get: async (id: string): Promise<any> =>
      api.getFile(`/api/v1/projects/files/${id}`),
  },
  get: async (filters: {
    limit?: number;
    offset?: number;
    search?: string;
    customerId?: string;
    start?: string;
    end?: string;
  }): Promise<IProjectGet[]> => api.get('/api/v1/projects', filters),
  getProject: async (id?: number): Promise<IProjectGetSingle> =>
    api.get(`/api/v1/projects/${id}`),
  hindrances: async (): Promise<IHindranceGet[]> =>
    api.get('/api/v1/projects/measurements/types/hindrance'),
  measurementCard: {
    get: async (projectId: any): Promise<IBasicInfoGet> =>
      api.get(`/api/v1/projects/${projectId}/measurement-card/basic-information
      `),
    put: async (projectId: any, basicInfo: any): Promise<any> =>
      api.put(
        `/api/v1/projects/${projectId}/measurement-card/basic-information`,
        basicInfo
      ),
  },
  places: {
    delete: (placeId: number): Promise<ISuccessResult> =>
      api.delete(`/api/v1/projects/places/${placeId}`),
    get: async (projectId: any): Promise<IPlaceGet[]> =>
      api.get(`/api/v1/projects/${projectId}/places`),
    getPlace: async (placeId: any): Promise<IPlaceGet> =>
      api.get(`/api/v1/projects/places/${placeId}`),
    post: async (projectId: any, place: any): Promise<any> =>
      api.post(`/api/v1/projects/${projectId}/places`, place),
    put: async (placeId: any, place: any): Promise<any> =>
      api.put(`/api/v1/projects/places/${placeId}`, place),
  },
  put: async (projectId: any, project: any) =>
    api.put(`/api/v1/projects/${projectId}`, project),
  tabs: {
    get: async (projectId: any): Promise<string[]> =>
      api.get(`/api/v1/projects/${projectId}/tabs`),
    post: async (projectId: any, tabs: any): Promise<any> =>
      api.post(`/api/v1/projects/${projectId}/tabs`, tabs),
  },
  status: {
    put: async (
      projectId: number,
      statuses: IPlaceStatus[]
    ): Promise<ISuccessResult> =>
      api.put(`/api/v1/projects/${projectId}/places/statuses`, statuses),
  },
  statusTypes: {
    get: async (): Promise<string[]> =>
      api.get('/api/v1/projects/measurements/types/place-status'),
  },
  tabTypes: {
    get: async (): Promise<string[]> => api.get('/api/v1/projects/types/tab'),
  },
  taxRateTypes: {
    get: async (): Promise<string[]> =>
      api.get('/api/v1/projects/types/tax-rate'),
  },
  wayOfReceivingTypes: {
    get: async (): Promise<string[]> =>
      api.get('/api/v1/projects/types/way-of-receiving'),
  },
  workGroups: {
    post: async (projectId: any, workgroup: IWorkGroupPost): Promise<any> =>
      api.post(`/api/v1/projects/${projectId}/workgroups`, workgroup),
  },
  workTypes: {
    get: async (): Promise<string[]> => api.get('/api/v1/projects/types/work'),
  },
};
