import IGlassConfiguratorProduct from 'interfaces/products/IGlassConfiguratorProduct';
import IMeasurement from 'interfaces/products/IMeasurement';
import IPrice from 'interfaces/products/IPrice';
import IProduct from 'api/interfaces/products/IProductGet';

import {
  LENGTH,
  KGM,
  PURCHASE_PRICE,
  WAREHOUSE,
  PAINTING_AREA,
  PAINTING_1,
  PAINTING_2,
  PAINTING_3,
  PAINTING_4,
  WASTE,
} from './glassConfiguratorFieldNames';

const getTempGlassConfiguratorProduct = (
  product: IProduct
): IGlassConfiguratorProduct => {
  const lengthTemp = product.measurements?.find(
    (measurement: IMeasurement) => measurement.type === LENGTH
  );

  const wasteTemp = product.measurements?.find(
    (measurement: IMeasurement) => measurement.type === WASTE
  );

  const kgMTemp = product.measurements?.find(
    (measurement: IMeasurement) => measurement.type === KGM
  );

  const purchaseTemp = product.prices?.find(
    (price: IPrice) => price.type === PURCHASE_PRICE
  );

  const warehouseTemp = product.prices?.find(
    (price: IPrice) => price.type === WAREHOUSE
  );

  const paintingAreaTemp = product.measurements?.find(
    (measurement: IMeasurement) => measurement.type === PAINTING_AREA
  );

  const painting1Temp = product.prices?.find(
    (price: IPrice) => price.type === PAINTING_1
  );

  const painting2Temp = product.prices?.find(
    (price: IPrice) => price.type === PAINTING_2
  );

  const painting3Temp = product.prices?.find(
    (price: IPrice) => price.type === PAINTING_3
  );

  const painting4Temp = product.prices?.find(
    (price: IPrice) => price.type === PAINTING_4
  );

  const productIds =
    product.productIds === undefined
      ? []
      : product.productIds.map((productId) => ({ ...productId }));

  return {
    categories: product.categories === undefined ? [] : [...product.categories],
    id: product.id,
    kgM: kgMTemp !== undefined ? kgMTemp.value.toString() : '',
    length: lengthTemp !== undefined ? lengthTemp.value.toString() : '',
    name: product.name ?? '',
    paintingArea:
      paintingAreaTemp !== undefined ? paintingAreaTemp.value.toString() : '',
    painting1:
      painting1Temp !== undefined ? painting1Temp.value.toString() : '',
    painting2:
      painting2Temp !== undefined ? painting2Temp.value.toString() : '',
    painting3:
      painting3Temp !== undefined ? painting3Temp.value.toString() : '',
    painting4:
      painting4Temp !== undefined ? painting4Temp.value.toString() : '',
    warehouse:
      warehouseTemp !== undefined ? warehouseTemp.value.toString() : '',
    purchasePrice:
      purchaseTemp !== undefined ? purchaseTemp.value.toString() : '',
    purchaseUnit: purchaseTemp !== undefined ? purchaseTemp.siUnit : '',
    productIds,
    imageId: product.imageId != null ? product.imageId : null,
    supplierId:
      product.supplier === undefined || product.supplier === null
        ? null
        : product.supplier.id,
    tags: product.tags === undefined ? [] : [...product.tags],
    waste: wasteTemp !== undefined ? wasteTemp.value.toString() : '',
  };
};

export default getTempGlassConfiguratorProduct;
