export default (
  value: number,
  roundToDecimals = 2,
  numberFormatOptions: any = { useGrouping: false }
): string => {
  return Number.isNaN(value)
    ? '0'
    : (
        Math.round(value * 10 ** roundToDecimals) /
        10 ** roundToDecimals
      ).toLocaleString('fi', numberFormatOptions);
};
